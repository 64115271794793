<template>
<div class="load-dash" v-show="prog!=100">
<!--<div v-if="prog>784" v-on:click="$router.push('/')" class="j7app-spacer right-bar-mar"
 style="display:flex;flex-wrap:wrap;justify-content:center;width:100%;
padding-right:14px;cursor:pointer;height:48px;max-height:48px;line-height:50px;
min-width:168px;max-width:168px;overflow:hidden;
justify-content:center;align-content:center;justify-items:center;align-items:center;">
<img src="/img/sm-logo-x.png" style="display:flex;justify-content:center;height:18px;object-fit:contain;" />
<span style="width:100%;height:100%;padding-right:4px;font-family: 'Noto Kufi Arabic', sans-serif;
font-size:18px;color:#4e4e4e;line-height:48px;">������ ������</span>
</div>-->
<div class="load-dash-box">
<div style="display:flex;flex-wrap:wrap;align-content:center;
justify-content:center;width:100%;height:52px;">
<img src="/img/darajaaa-logo-128.png" style="display:flex;flex-wrap:wrap;
justify-content:center;
max-height:60px;position:relative;padding-left:5px;align-content:center;" />
</div>
<div class="order-timeline-item-ready" style="position:relative;min-width:36px;
display:none;flex-wrap:wrap;justify-items:center;">
<div class="circle ready"
style="display:flex;position:absolute;width:36px;height:36px;top:16px;left:55%;"></div>
<span class="order-timeline-on" 
style="position:relative;display:flex;background:#fff;width:32px;height:32px;border-radius:50%;">
<span style="position:relative;display:flex;color:#dc3545;margin:auto;" class="mdi mdi-18px mdi-check-bold"></span>
</span>
</div>
<div style="position:relative;display:flex;flex-wrap:wrap;width:100%;height:100%;justify-content:center;padding:24px;">
<div class="line-progress">
<span class="line-progress-span" :style="{width: prog + '%'}"></span>
</div>
</div>
</div>
</div>
</template>
<script>
export default {
name: 'LoadBar',
components: {},
props: ['plus','time'],
emits:['load_state'],
data(){
return {
prog:0,interval:null
}
},
methods: {
emit_load_state: function (){ 
this.$emit('load_state', this.prog);
}
},
mounted(){
this.interval = setInterval(() => {this.prog=this.prog + this.plus}, this.time);
},
watch:{
prog(){
if(this.prog>95){
clearInterval(this.interval);
this.prog=100;
this.emit_load_state();
}
}
}
}
</script>
<style scoped>
.line-progress{display:flex;flex-wrap:wrap;background:#ddd;border:1px solid #ccc;
height:5px;width:192px;max-width:192px;overflow:hidden;border-radius:10px;justify-content:left;align-content:center;}
.line-progress-span{display:flex;flex-wrap:wrap;background:#dc3545;width:0%;height:4px;justify-content:center;}
.load-dash{
background-color:#fff;min-width:100%;
height:100vh;height:-webkit-fill-available;display:flex;flex-wrap:wrap;
justify-content:center;align-content:center;
margin:auto;
}
.load-dash-box{
border-radius:10px; background-color:#fff; display:flex;flex-wrap:wrap;
min-width:100%;width:100%;
justify-content:center;margin:auto;padding-top:14px;padding-bottom:14px;
}
.load-vapp{display:none!important;}
.login_box{display:none!important;}
</style>
<style scoped>
.shdow{
box-shadow: 6px 11px 41px -28px #f1f1f1;
  -webkit-box-shadow: 6px 11px 41px -28px #f1f1f1;
}
.el-progress-bar__outer{ background-color:#555; border-radius: 10px!important; }
.el-progress-bar__inner{text-align:center;font-size:15px;border-radius: 10px!important; }
.el-progress-bar__innerText{color:#fff!important;font-size:15px; }
.circle {
border-radius: 50%;
transform: translate(-50%, -50%);z-index:11;
}
.circle:before,
.circle:after {
content: '';
display: block;
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
border: 1px solid #ccc;
border-radius: 50%;
}
.circle.done:before,
.circle.done:after {
border: 1px solid #20deb7;
}
.circle.ready:before,
.circle.ready:after {
border: 1px solid #ffc107;
}
.circle.pending:before,
.circle.pending:after {
border: 1px solid #ffc107;
}

.circle:before {
animation: ripple 1s linear infinite;
}
.circle:after {
animation: ripple 1s linear 1s infinite;
}

@keyframes ripple {
0% {
transform: scale(1);
}
50% {
transform: scale(1.3);
opacity: 1;
}
100% {
transform: scale(1.6);
opacity: 0;
}
}
</style>
