<template>
<div class="codeview-box">
<div class="pop-up-box">
<div class="pop-up-com">
<div class="pop-up-com-item animate__animated animate__bounce">
<div style="font-family: 'Noto Kufi Arabic', sans-serif;font-size:15px;display: flex; flex-wrap:wrap;
height:auto;color:#353c45; width: 100%;justify-content:center; align-content: center;margin:auto;
padding:14px;max-width:400px;">
<div style="margin-top:14px;height: 45px; font-size: 24px;display: flex; flex-wrap:wrap; align-content: center;justify-content: center !important; width: 100%;touch-action: pan-y !important; ">
<img src="/img/slid-logo.png" style="display:flex;max-height:45px;object-fit:contain;overflow:hidden;" />
</div>
<div style="font-size: 18px;padding: 7px 0px 7px 0px;display: flex; flex-wrap:wrap; align-content: center;justify-content: center !important; width: 100%; touch-action: pan-y !important; ">
</div>
<div style="margin-top:7px;margin-bottom:24px;color: #4e4e4e;display: flex; flex-wrap:wrap; align-content: center;justify-content: center !important; width: 100%;touch-action: none; ">
<input v-model="input_code" type="text" lang="en" v-on:keyup.enter="view_post" 
  inputmode="numeric" maxlength="4" v-on:keypress="isNumberKey"
 placeholder=" .. اضف الكود هنا .." style="min-width:255px;font-size: 16px;"
 :class="code_ck==true ? 'in_code val-success':'in_code val-error'">
</div>
<div v-on:click="view_post"
 style="display: flex;flex-wrap:wrap; align-content: center;justify-content: center !important;
background:#353c45!important;
background-repeat:no-repeat;
color:#fff;border-radius:8px;
min-width:255px;height:60px;max-height:60px!important;">
<span style="display: flex; flex-wrap:wrap; align-content: center; width:auto!important;height:55px!important;">
<span style="font-size: 18px;">عرض التفاصيل </span>
</span>
</div>
</div>
</div>
</div>
</div>
</div>
</template>
<script>
import { mapState } from 'vuex';
export default {
name: 'CodeView',
components: {},
//props: {msg: String},
data(){
return {
ck_posts:[],
code_ck:false,
input_code:'',
popup:''    
}
},
methods: {
view_post:function () {
var regex = new RegExp(/([0-9])$/);
if (this.input_code != '' && regex.test(this.input_code))
{
this.ck_posts=this.posts_code.filter(item =>
{
//return this.search_val.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v))
return item.code.toString()==this.input_code.toString().toLowerCase()
}).map(item => {return item.link.toString()});
if(this.ck_posts.length>0) {
this.$router.push({name: 'HomeItemView', params: { guid: this.ck_posts[0] }});
}
else{
this.Swal_Error('الكود غير صحيح !!');
}
}
else{
this.Swal_Error('الرجاء اضافة الكود !!');
}
},
Swal_Error:function (val) {
this.$store.commit("Swal_Error", val);
},
isNumberKey: function (event){
//  var regex = new RegExp(/^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/);
/*  if (regex.test(this.input_mobile)) {this.mobile_ck = true;}*/
var charCode = (event.which) ? event.which : event.keyCode
if (charCode > 31 && (charCode < 48 || charCode > 57)
 && (charCode < 1632 || charCode > 1641) )
{
this.input_phone = this.input_phone.toString().replaceAll(/[^0-9]/g, '');
this.input_val = this.input_val.toString().replaceAll(/[^0-9]/g, '');
//this.input_mobile = this.input_mobile.slice(0, -1);
return this.Swal_Error('الحروف غير مسموح بها !!');
}
return true;
},
btn_show:function (val){
$(val).hide();
$(val).show();
$(val).removeClass("animate__animated animate__fadeOutLeft animate__faster");
$(val).addClass("animate__animated animate__fadeInLeft animate__faster");
},
},
mounted(){
document.title='عرض | QrCode';
},
watch:{
input_code(){
var v=this.input_code;
var regex = new RegExp(/[\u0660-\u0669]/g);
if(regex.test(v)){
this.input_code=this.input_code.replace(/[\u0660-\u0669]/g, (c) =>
String.fromCharCode(c.charCodeAt(0) - 0x0660 + 0x0030)
);
}
},
// whenever question changes, this function will run
pageview: function () {
//this.$refs.horizontal.scrollToIndex(this.pageview + 1);
}
},
computed: {
...mapState({
posts_code : state => state.posts_code,
cust_name : state => state.cust_name,

})
}
}
</script>
<style>
h5{font-size:24px;text-align:center;padding:0px 0 14px 0;display: flex;flex-wrap:wrap;justify-content:center;align-content:center; }
</style>
<style scoped>
.codeview-box{background:#fff;position:fixed;bottom:0;z-index:10; display: flex;flex-wrap:wrap; width:100%; height: 65px; max-height: 65px;font-family: 'Cairo', sans-serif;font-weight:400;border: 0px solid transparent;box-shadow: 0px 5px 5px 0px rgba(82, 63, 105, 0.05); background-position: center;touch-action: none;overflow:hidden;cursor:pointer;justify-content: center;align-content:center;  }
.pop-up-box{z-index:10;position:fixed;top:0;right:0;
background: rgba(0, 0, 0, 0.5);
backdrop-filter: blur(8px);
-webkit-backdrop-filter: blur(8px);
display:flex;flex-wrap:wrap;width:100%;min-height: 100vh;max-height:100vh;overflow:hidden;
min-height: -webkit-fill-available; user-select: none;margin-right:auto;margin-left:auto;
align-content:center;justify-content:center;
}
.pop-up-com{z-index:1;position:relative;
display:flex;flex-wrap:wrap;width:100%;height:100%;justify-content:center;
margin-right:auto;margin-left:auto;color:#000;align-content:center;
padding:14px;
}
.pop-up-com-item{
z-index:1;position:relative;background-color:#fff;border-radius:14px;
display:flex;flex-wrap:wrap;width:auto;height:100%;justify-content:center;
margin-right:auto;margin-left:auto;color:#000;align-content:center;
padding-bottom: 24px;
}
input[type="text"] {
background-color: rgb(252, 252, 251);
min-height:54px;
border:1px solid #aaaaaa;
border-radius: 4px;
font-size:16px;
outline: none !important;
overflow: hidden !important;
text-align:center;
}
</style>
