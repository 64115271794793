<template>
<div class="home-som-box" v-if="somList.length>0">
<div class="home-som-box-item" style="width:100%;">
<div class="home-som-box-item som-dt" style="">
<div class="home-som-rap" style="width:100%;">✔︎
<span>عدد </span>
<span>السومات</span>
</div>
<div class="home-som-rap mtop">
<div class="home-som-rap progbar" style="width:50%;">
<span class="home-som-rap progbar-span" style="background:#54b371;"></span>
</div>
</div>
<div class="home-som-rap mtop">
<span v-if="somList">{{somList[0].som_count}}</span>
<span v-else>0</span>
</div>
</div>
<div class="home-som-box-item som-dt" style="">
<div class="home-som-rap" style="width:100%;">✔︎ 
<span>تم </span>
<span>قبول</span>
</div>
<div class="home-som-rap mtop">
<div class="home-som-rap progbar" style="width:50%;">
<span class="home-som-rap progbar-span" style="background:#54b371;"></span>
</div>
</div>
<div class="home-som-rap mtop">
<span v-if="somList">{{somList[0].som_acc_count}}</span>
<span v-else>0</span>
</div>
</div>
<div class="home-som-box-item som-dt" style="">
<div class="home-som-rap" style="width:100%;">✔︎ 
<span>زيادة </span>
<span>السوم</span>
</div>
<div class="home-som-rap mtop">
<div class="home-som-rap progbar" style="width:50%;">
<span class="home-som-rap progbar-span" style="background:#54b371;"></span>
</div>
</div>
<div v-if="somList[0].som_plus>0" class="home-som-rap mtop">
<span v-if="somList">من {{somList[0].som_plus}}</span>
<span v-else>0</span>
<span style="padding-right:6px;">ريال</span>
</div>
</div>
<div class="home-som-box-item som-dt" style="">
<div class="home-som-rap" style="width:100%;">✔︎
<span>اخر </span>
<span>سومه</span>
</div>
<div class="home-som-rap mtop">
<div class="home-som-rap progbar" style="width:50%;">
<span class="home-som-rap progbar-span" style="background:#54b371;"></span>
</div>
</div>
<div class="home-som-rap mtop">
<span v-if="somList">{{somList[0].som_end}}</span>
<span v-else>0</span>
<span style="padding-right:6px;">ريال</span>
</div>
</div>
</div>
</div>
</template>
<script>
import { mapState} from "vuex";
export default {
name: 'ItemSomStateus',
components: {},
props: ['link'],
data(){
return {
}
},
methods: {
set_pop_som:function(val){ 
this.$store.commit("set_pop_som",val);
}
},
computed: {
...mapState({
som : state => state.som,
}),
somList(){
return this.som.filter(item =>{
return item.link.toString()==this.link.toString()
});
}
},
watch:{

}
}
</script>
<style scoped>
.mohid{display:none;}
.progbar{
width:22%;height:3px;margin:auto;border-radius:12px;
background:#cfd8dc;align-content:center;
}
.progbar-span{
align-content:center;width:50%;height:3px;margin-left:auto;
border-radius:12px;
}
.home-som-box{font-family: 'Noto Kufi Arabic', sans-serif;
width:100%;display: flex; flex-wrap: wrap;justify-content:center;align-content:center;
margin:auto;}
.home-som-rap{
display: flex; flex-wrap: wrap;justify-content:center;align-content:center;
position:relative;
}
.home-som-box-item{
display: flex; flex-wrap: wrap;justify-content:center;align-content:center;
color:#2e2e2e;font-weight:500;white-space: pre-wrap;font-size:15px;
}
.som-dt{
width:46%;color:#2e2e2e;height:100px;margin:2%;
align-items:center;border: 1px solid #eee;
border-radius:12px;
}
.mtop{margin-top:7px;width:100%;}
@media (min-width:400px){
.progbar{width:25%;}
}
@media (min-width:450px){
.progbar{width:30%;}
.mohid{display:flex;}
}
@media (min-width:800px) and (max-width:1024px){
.progbar{width:35%;}
.som-dt{width:19.5%;padding-top:4px;border-radius:12px;height:128px;}
.mtop{margin-top:12px;width:100%;}
.mohid{display:flex;}
}
@media (min-width:1024px) and (max-width:1200px){
.som-dt{width:43%;padding-top:4px;border-radius:12px;height:150px;}
}
@media (min-width:1200px) and (max-width:1360px){
.som-dt{width:21%;padding-top:4px;border-radius:12px;height:150px;}
.progbar{height:5px;}
.progbar-span{height:5px;}
}
@media (min-width:1400px){
.som-dt{width:128px;padding-top:4px;border-radius:12px;height:128px;
border-radius:12px;
}
.progbar{height:2px;}
.progbar-span{height:2px;}
}

</style>
