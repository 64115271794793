<template>
<div class="PageHome j7app-main-com" dir="rtl">
<div class="home-nav j7app-header-home" style="box-shadow: 0px 1px 1px 0px #f7f6f6;">
<TopNavbar style=""></TopNavbar>
</div>
<div class="main-home-page j7app-main-home" :style="view_Nav=='menu'?'padding-top:152px;':'padding-top:60px;'">
<ItemListV2></ItemListV2>
</div>
<Footer v-if="prog==100"></Footer>
<BottomNav></BottomNav>
<div class="LoadBar" style="background:#fff;position:fixed;width:100%;
height: 100vh; height: -webkit-fill-available;
touch-action: pan-x pan-y;
z-index:10;top:0;left:0;display:flex;flex-wrap:wrap;">
<LoadBar @load_state="emit_load_state($event)" :plus="5" :time="10"></LoadBar>
</div>
<div>
<CodeView></CodeView>
</div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import TopNavbar from '../components/TopNavbar.vue'
import ItemListV2 from '../components/ItemListV2.vue'
import BottomNav from '../components/BottomNav.vue'
import Footer from '../components/Footer.vue'
import LoadBar from '../components/LoadBar.vue'
import CodeView from '../components/CodeView.vue'

export default {
name: 'Codepage',
components: {
TopNavbar,BottomNav,Footer,ItemListV2,LoadBar,CodeView
},
data() {
return {
code_view:'',
prog:0
}
},
methods: {
emit_load_state:function(event){ 
this.prog = event;
}
},
mounted() {
if(this.posts.length<=0) {
this.$store.commit("GetApiJson","A6989477-31E7-4757-8D37-E64F131KKKKD");
this.$store.commit("GetPostsCode","A6989477-31E7-4757-8D37-E64F131KKKKD");
}
this.$store.commit("set_page_title",this.cust_name);
},
updated() {
//alert('updated');
},
watch:{
prog(){
if(this.prog==100) {
$('.LoadBar').hide();
}
}
},
computed: {
...mapState({
pageview : state => state.pageview,
posts : state => state.posts,
view_Nav : state => state.view_Nav,
cust_name : state => state.cust_name
})
}
}
</script>
<style>
/*
background:linear-gradient(45deg, #f5f5f5,#e6e6e6);
background:#f7f6f6;
*/
.j7app-main-com{
position:relative;z-index:0;
width: 100%;display: flex;align-content:flex-start;
flex-wrap: wrap;margin-right:auto;margin-left:auto;
}
.j7app-header-home{
position: fixed;z-index:1;
display: flex; flex-wrap: wrap;
width: 100%;margin-right:auto;margin-left:auto;max-height:158px;
align-items: flex-start;touch-action: pan-x;
border-radius:0; border: 0px solid transparent;
background:#f7f6f6;
padding:0;
}
.j7app-main-home{
position:relative;z-index:0;
width: 100%;display: flex;align-content:flex-start;
flex-wrap: wrap;margin-right:auto;margin-left:auto;
background:#fff;
}

</style>