<template>
<div class="home-item-view" style="position:relative;">
<div class="j7-view-item" v-for="(item, index) in filteredList"
 style="display: flex; flex-wrap: wrap;width:100%;justify-content:center;">
<div class="j7-view-item-pc" style="max-width:1366px;
background:rgba(255,255,255,0.98);">
<div class="j7-view-item-pc-right" style="display:flex;flex-wrap:wrap;width:50%;align-content:flex-start;padding-top:24px;">
<ItemTitlePc :title="item.title" :address="item.address" style="justify-content:center;
height:38px;align-content:center;margin-bottom:18px;
font-size:18px;font-family: 'Noto Kufi Arabic', sans-serif;"></ItemTitlePc>
<div class="j7-view-item-pc-right-box" style="margin-top:7px;position:relative;
display:flex;flex-wrap:wrap;width:100%;align-content:flex-start;">
<ItemImage :imgaes="item.imgaes" :soldout="item.available"></ItemImage>
</div>
<div class="relative-Whatsapp-box" style="flex-wrap:wrap;justify-content:center;position:relative;
z-index:1;background:none;width:100%;">
<WhatsappRel :text="'مرحبا , اتواصل معكم بخصوص - '+item.title+' - كود رقم - '+item.code"></WhatsappRel>
</div>
<ItemDesc v-if="item.desc!='<p><br></p><p><br></p><p><br></p>'" :desc="item.desc" style="padding:14px;"></ItemDesc>
</div>
<div class="j7-view-item-pc-left" style="display:flex;flex-wrap:wrap;width:50%;align-content:flex-start;padding-top:10px;">
<ItemDetails :vitem="item" :details="item.details" :features="item.features"></ItemDetails>
<div class="relative-som-btn-box" v-if="item.som==true"
style="flex-wrap:wrap;justify-content:center;position:relative;
z-index:1;background:none;width:100%;padding-right:18px;padding-left:18px;">
<div class="j7-view-box-h2" style="margin-bottom:7px;">
<div class="j7-view-h2">
<span class="brk7"></span>
<span style="padding-right:10px;color:#4e4e4e;font-size:18px;margin-top:14px;">
احصائيات السوم
</span>
<span class="mdi mdi-24px mdi-chevron-down" 
style="color:#4e4e4e;margin-right:5px;padding-left:14px;margin-top:14px;"></span>
<span class="brk7"></span>
</div>
</div>
<ItemSomStateus style="margin-bottom:14px;" :link="item.short_link" v-if="item.som==true">
</ItemSomStateus>
<ItemSomBtn style="" :link="item.short_link" v-if="item.som==true&&item.available==true">
</ItemSomBtn>
</div>
</div>
<div style="display:flex;flex-wrap:wrap;justify-content:center;height:auto;width:100%;padding:14px;
margin-bottom:24px;">
<!--<ItemMapsPc :maps="item.maps" :latitude="item.latitude" :longitudes="item.longitudes"></ItemMapsPc>-->
<!--<ViewSlider></ViewSlider>-->
<div style="line-height:55px;height:55px;
border-radius:4px;align-content:center;
display: flex; flex-wrap: wrap;width:100%;justify-content:center;position:relative;">
<div class="brk7"></div>
<div class="j7-view-details-item-name" style="display: flex; flex-wrap: wrap;height:55px;line-height:55px;
position:relative;width:100%;justify-content:right;align-content:center;">
<span style="padding-right:10px;color:#4e4e4e;font-size:18px;font-family: 'Noto Kufi Arabic', sans-serif;">
المزيد من العروض
</span>
<span class="mdi mdi-24px mdi-chevron-down" style="color:#4e4e4e;margin-right:5px;padding-left:14px;"></span>
</div>
<div class="brk7"></div>
</div>
<!--<NavbarBottom></NavbarBottom>-->
<!-- ['title','tags','address','model','brand','price'] -->
<ItemListBottomV2 :title="item.title"
:tags="item.tags" :address="item.address" 
:model="item.model" :brand="item.brand" :price="item.price">
</ItemListBottomV2>
<div style="line-height:55px;height:55px;
border-radius:4px;align-content:center;
display: flex; flex-wrap: wrap;width:100%;justify-content:center;position:relative;">
<div class="brk7"></div>
<div class="j7-view-details-item-name" style="display: flex; flex-wrap: wrap;height:55px;line-height:55px;
position:relative;width:100%;justify-content:right;align-content:center;">
<span style="padding-right:10px;color:#4e4e4e;font-size:18px;font-family: 'Noto Kufi Arabic', sans-serif;">
ابحث عن العروض
</span>
<span class="mdi mdi-24px mdi-chevron-down" style="color:#4e4e4e;margin-right:5px;padding-left:14px;"></span>
</div>
<div class="brk7"></div>
</div>
<ViewTags></ViewTags>
</div>
</div>
<div class="j7-view-item-mobile-ipad" style="position:relative;z-index:1;background:#fff;">
<ItemTitle :title="item.title"></ItemTitle>
<!--<ItemAddress :address="item.tags"></ItemAddress>-->
<ItemImage :imgaes="item.imgaes" :soldout="item.available" style="position:relative;">
</ItemImage>
<div class="relative-Whatsapp-box" style="flex-wrap:wrap;justify-content:center;position:relative;
z-index:1;background:none;width:100%;">
<WhatsappRel :text="'مرحبا , اتواصل معكم بخصوص - '+item.title+' - كود رقم - '+item.code"></WhatsappRel>
</div>
<div class="relative-som-btn-box" v-if="item.som==true"
style="flex-wrap:wrap;justify-content:center;position:relative;
z-index:1;background:none;width:100%;padding-right:18px;padding-left:18px;">
<ItemSomBtn :link="item.short_link" v-if="item.som==true&&item.available==true">
</ItemSomBtn>
<div class="j7-view-box-h2" style="margin-bottom:14px;border-top: 1px solid #eee;">
<div class="j7-view-h2">
<span class="brk7"></span>
<span style="padding-right:10px;color:#4e4e4e;font-size:18px;margin-top:14px;">
احصائيات السوم
</span>
<span class="mdi mdi-24px mdi-chevron-down" 
style="color:#4e4e4e;margin-right:5px;padding-left:14px;margin-top:14px;"></span>
<span class="brk7"></span>
</div>
</div>
<ItemSomStateus style="margin-bottom:24px;" :link="item.short_link" v-if="item.som==true">
</ItemSomStateus>
</div>
<ItemDetails :vitem="item" :details="item.details" :features="item.features"></ItemDetails>
<ItemDesc v-if="item.desc!='<p><br></p><p><br></p><p><br></p>'" :desc="item.desc" :style="item.desc!=''?'padding:14px;':''"></ItemDesc>
<!--<ItemMaps :maps="item.maps" :latitude="item.latitude" :longitudes="item.longitudes"
style="padding-top:24px;"></ItemMaps>-->
<!--<ViewSlider></ViewSlider>-->
<div style="display:flex;flex-wrap:wrap;justify-content:center;height:auto;width:100%;padding:14px;
margin-bottom:24px;">
<div class="j7-view-box-h2">
<div class="j7-view-h2">
<span class="brk7"></span>
<span style="padding-right:10px;color:#4e4e4e;font-size:18px;">
ابحث عن العروض
</span>
<span class="mdi mdi-24px mdi-chevron-down" 
style="color:#4e4e4e;margin-right:5px;padding-left:14px;"></span>
<span class="brk7"></span>
</div>
</div>
<ViewTags2></ViewTags2>
<div class="j7-view-box-h2">
<div class="j7-view-h2">
<span class="brk7"></span>
<span style="padding-right:10px;color:#4e4e4e;font-size:18px;">
المزيد من العروض
</span>
<span class="mdi mdi-24px mdi-chevron-down" 
style="color:#4e4e4e;margin-right:5px;padding-left:14px;"></span>
<span class="brk7"></span>
</div>
</div>
<ItemListBottomV2 :title="item.title"
:tags="item.tags" :address="item.address" 
:model="item.model" :brand="item.brand" :price="item.price">
</ItemListBottomV2>
</div>
</div>
<div class="fix-Whatsapp-box" style="display:none;flex-wrap:wrap;justify-content:center;position:fixed;
bottom:31.75%;right:32px;z-index:0;background:none;width:100%;">
<Whatsapp :text="'مرحبا , اتواصل معكم بخصوص - '+item.title+' - كود رقم - '+item.code"></Whatsapp>
</div>
</div>
</div>
</template>
<script>
import {mapState} from 'vuex';
/*import VueHorizontal from 'vue-horizontal';*/
import ItemNavbar from '../components/ItemNavbar.vue';
import ItemImage from '../components/ItemImage.vue';
import ItemTitle from '../components/ItemTitle.vue';
import ItemTitlePc from '../components/ItemTitlePc.vue';
import ItemAddress from '../components/ItemAddress.vue';
import ItemPrice from '../components/ItemPrice.vue';
import ItemRinfo from '../components/ItemRinfo.vue';
import ItemDesc from '../components/ItemDesc.vue';
import ItemDetails from '../components/ItemDetails.vue';
import NavbarBottom from '../components/NavbarBottom.vue';
import ItemListBottomV2 from '../components/ItemListBottomV2.vue';
import ItemListBottomV2pc from '../components/ItemListBottomV2pc.vue';
import ItemListBottom from '../components/ItemListBottom.vue';
import Whatsapp from '../components/Whatsapp.vue';
import WhatsappRel from '../components/WhatsappRel.vue';
import ItemMaps from '../components/ItemMaps.vue';
import ItemMapsPc from '../components/ItemMapsPc.vue';
import SoldOutV1 from '../components/SoldOutV1.vue';
import SoldOutV2 from '../components/SoldOutV2.vue';
import ViewTags from '../components/ViewTags.vue';
import ViewTags2 from '../components/ViewTags2.vue';
import ViewSlider from '../components/ViewSlider.vue';
import ItemSomBtn from '../components/ItemSomBtn.vue';
import ItemSomStateus from '../components/ItemSomStateus.vue';
import axios from 'axios';
export default {
name: 'ItemView',
components:{
ItemNavbar,ItemTitle,ItemAddress,ItemPrice,ItemRinfo,ItemTitlePc,
ItemImage,ItemDesc,ItemDetails,NavbarBottom,ItemListBottom,ItemListBottomV2,
Whatsapp,WhatsappRel,ItemMaps,ItemListBottomV2pc,ItemMapsPc,SoldOutV1,SoldOutV2,
ViewTags,ViewTags2,ViewSlider,ItemSomBtn,ItemSomStateus
},
//props: {msg: String},
data(){
return {
search_val:this.$route.params.guid
}
},
methods:{
GetVisitor(dash_key,link){
axios.get(this.api_url+'pgview.aspx?u='+dash_key+'&link='+link,{
headers: {
//"Accept-Language": "en-US,en;"
"Accept-Language": "ar,ar-sa;"
}
})
.then((response) => {
if(response.data.status=='ok'){
this.Visitor=response.data;
}
else{

}
},(error) => {

});
},
lazy_update(){
this.$store.commit("lazy_update");
}
},
created() {
this.search_val=this.$route.name;
},
mounted(){
this.lazy_update();
this.search_val=this.$route.params.guid;
this.GetVisitor(this.dash_key,this.search_val);
this.$store.commit("GetSomJson",this.dash_key);
},
updated() {
//var lazyLoadInstance = new LazyLoad({});
this.lazy_update();
},
computed: {
...mapState({
selctedCat : state => state.selctedCat,
view_Nav:state => state.view_Nav,
//db : state => state.db,
cats : state => state.cats,
items : state => state.items,
posts : state => state.posts,
hosturl : state => state.hosturl,
api_url : state => state.api_url,
dash_key : state => state.dash_key,
currency : state => state.currency,
hide_navbar : state => state.hide_navbar,
preview_index : state => state.preview_index,
pageview:state => state.pageview,
update_post_val:state => state.update_post_val
}),
filteredList(){
return this.posts.filter(item =>
{
//return this.search_val.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v))
var query=this.search_val.toString().replaceAll('-',' ').toLowerCase();
return item.short_link.toString()==this.search_val.toString()
||item.code.toString()==this.search_val.toString()
||item.guid.toString()==this.search_val.toString()
||item.tags.toString().includes(query)
||item.title.toString().toLowerCase().includes(query)
||item.subtitle.toString().toLowerCase().includes(query)
||item.type.toString().toLowerCase().includes(query)
||item.price.toString().toLowerCase().includes(query)
||item.area.toString().toLowerCase().includes(query)
||item.model.toString().toLowerCase().includes(query)
||item.brand.toString().toLowerCase().includes(query)
||item.brand_type.toString().toLowerCase().includes(query)
||item.gir.toString().toLowerCase().includes(query)
||item.desc.toString().toLowerCase().includes(query)  
||item.details.toString().toLowerCase().includes(query)
||item.features.toString().toLowerCase().includes(query);
});
}
},
watch:{
update_post_val(){
this.search_val=this.update_post_val;
$('img.lazy').removeAttr('data-ll-status');
this.lazy_update();
}
}
}
</script>
<style scoped>
.home-som-rap{
display: flex; flex-wrap: wrap;justify-content:center;align-content:center;
position:relative;font-family: 'Noto Kufi Arabic', sans-serif
}
.j7-view-box-h2{
position:relative;border-radius:4px;align-content:center;
display: flex; flex-wrap: wrap;width:100%;justify-content:center;
line-height:55px;height:55px;
}
.j7-view-h2{
display: flex; flex-wrap: wrap;height:55px;line-height:55px;
position:relative;width:100%;justify-content:right;align-content:center;
font-family: 'Noto Kufi Arabic', sans-serif
}
.j7-view-h2 span{

}
.brk24{display:flex;flex-wrap:wrap;width:100%;min-height:24px;position:relative;}
.brk14{display:flex;flex-wrap:wrap;width:100%;min-height:14px;position:relative;}
.home-item-view{
display:flex;flex-wrap:wrap;justify-content:center;align-items:center;margin:0;
box-sizing: content-box;
overflow-x: hidden;width:100%;
overflow-y: hidden;scrollbar-width:none;
}
.j7-view-item-right{display:flex;flex-wrap:wrap;width:100%;position:relative;
justify-content:center;align-content:flex-start;
}
.j7-view-item-mid{display:flex;flex-wrap:wrap;width:100%;position:relative;
justify-content:center;align-content:flex-start;
}
.j7-view-item-left{display:flex;flex-wrap:wrap;width:100%;position:relative;
justify-content:center;align-content:flex-start;
}
</style>
<style scoped>

@media (min-width: 200px) and (max-width: 575px){
.j7-view-item-pc{width:100%;display:none!important;}
.j7-view-item-mobile-ipad{width:100%;display:flex!important;display:flex!important;flex-wrap:wrap;}
.fix-price{margin-right:0;}
}

/*@media (min-width: 800px){
.fix-price{margin-right:24px;}
.j7-view-item-right{width:100%;}
.j7-view-item-mid{width:100%;}
.j7-view-item-left{width:100%;display:none!important;}
}*/
@media (min-width: 575px) and (max-width: 800px){
.j7-view-item-mobile-ipad{width:100%;display:flex!important;display:flex!important;flex-wrap:wrap;}
.j7-view-item-pc{width:100%;display:none!important;}
}
@media (min-width: 800px) and (max-width: 1024px){
.j7-view-item-mobile-ipad{width:100%;display:flex!important;display:flex!important;flex-wrap:wrap;}
.j7-view-item-pc{width:100%;display:none!important;}
}
@media (min-width: 1024px){
.hide-details{display:none!important;}
.j7-view-item-mobile-ipad{width:100%;display:none!important;}
.j7-view-item-pc{width:100%;display:flex!important;flex-wrap:wrap;}
}
</style>
