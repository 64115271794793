<template>
<div class="PageHome j7app-item-com" dir="rtl" 
style="position:relative;
background:#412c26 url(/img/bg-iphone.png);
background-size:cover;background-repeat:repeat;background-position:center;">
<div class="pop-up-box" v-on:click.self="set_pop_som('')"
style="backdrop-filter: blur(8px);
-webkit-backdrop-filter: blur(8px);background:rgba(75,75,75,.2);">
<SomAccpet :link="pop_som" style="max-width:475px;touch-action:pan-x pan-y!important;"></SomAccpet>
</div>
</div>
</template>
<script>
import { mapState } from 'vuex';
//import TopItembar from '../components/TopItembar.vue'
//import Navbar from '../components/navbar.vue'
//import ItemView from '../components/ItemView.vue'
//import BottomNav from '../components/BottomNav.vue'
//import Footer from '../components/Footer.vue'
//import LoadBar from '../components/LoadBar.vue'
import SomAccpet from '../components/SomAccpet.vue'

export default {
name: 'SomPageAccept',
components: {
//TopItembar,Navbar,ItemView,BottomNav,Footer,LoadBar,
SomAccpet
},
data() {
return {
prog:0
}
},
methods: {
set_pop_som:function(val){ 
this.$store.commit("set_pop_som",val);
},
emit_load_state:function(event){ 
this.prog = event;
}
},
mounted() {
//document.title=this.cust_name;
$('.j7app-containr').scrollTop(1);
},
updated() {
//alert('updated');
},
watch:{
prog(){
if(this.prog==100) {
$('.LoadBar').hide();
}
}
},
computed: {
...mapState({
pageview : state => state.pageview,
cust_name : state => state.cust_name,
pop_som : state => state.pop_som,
})
}
}
</script>
<style scoped>
/*
background:linear-gradient(45deg, #f5f5f5,#e6e6e6);
background:#f7f6f6;
*/
.pop-up-box{
z-index:10;position:fixed;top:0;right:0;
background: rgba(0, 0, 0, 0.5);
backdrop-filter: blur(8px);
-webkit-backdrop-filter: blur(8px);height:100%;
display:flex;flex-wrap:wrap;width:100%;max-height:100vh;overflow:hidden;
max-height: -webkit-fill-available; user-select: none;margin-right:auto;margin-left:auto;
align-content:center;justify-content:center;
cursor:pointer;
}
.j7app-item-com{
position:relative;z-index:0;
width: 100%;display: flex;align-content:flex-start;
flex-wrap: wrap;margin-right:auto;margin-left:auto;
}
.j7app-item-header{
position: fixed;z-index:1;
display: flex; flex-wrap: wrap;
width: 100%;margin-right:auto;margin-left:auto;max-height:158px;
align-items: flex-start;touch-action: pan-x;
border-radius:0; border: 0px solid transparent;
box-shadow: 0px 1px 1px 0px #f7f6f6;
padding:0;
}
.j7app-item-main{
position:relative;z-index:0;
width: 100%;display: flex;align-content:flex-start;
flex-wrap: wrap;margin-right:auto;margin-left:auto;
padding-top:55px;
}
@media (min-width: 900px){
.j7app-item-header{
position: relative!important;width: 100%!important;
box-shadow:none;}
.j7app-item-main{padding-top:0;}
}
</style>