<template>
<div class="top_btn j7app-navbar" v-show="view_Nav=='menu'">
<div class="swiper_nav j7app-navbar-swbox" v-show="view_Nav=='menu'">
<vue-horizontal class="horizontal" ref="horizontal" snap="none" :button="false">
<!--item-home-nbtn--> 
<div class="j7app-navbar-item" :class="nav_index==index ? 'active':''"
 v-opacity="0.6" v-for="(item, index) in cats"
 v-on:click="nav_index=index,setpage(index),SetViewCat(item.link,item.Aname)">
<!--<img src="/img/nv-5ed.png" style="max-height:12px;" />-->
<span class="mdi mdi-18px mdi-checkbox-blank-circle-outline frp" 
 style="position:relative;height:18px;width:18px;
border-radius:50%;background-color:#fff;">
<span class="span-fixbtn" v-show="nav_index==index"
 style="height:18px;width:18px;padding:1px;">
<span class="span-fixbtn-bg"
 style="height:50%;width:50%;background:#5fda9f;"></span>
</span>
</span>
<a class="nav-icon_titel j7app-navbar-item-name" style="padding-left:4px;">
{{item.Aname}}
</a>
</div>
</vue-horizontal>
</div>
</div>
</template>
<script>
import { mapState } from 'vuex';
import VueHorizontal from 'vue-horizontal';
import CountItemByCatV2 from '../components/CountItemByCatV2.vue'
export default {
name: 'Navbar',
components: {VueHorizontal,CountItemByCatV2},
//props: {msg: String},
data(){
return {
nav_index:0     
}
},
methods: {
Navhorizontal:function (val){
//alert(0);
//this.$store.commit("Navhorizontal", val);
},
SetViewCat: function (val,name) {
this.$store.commit("set_pop_search",'')
this.$store.commit("SetViewCat", val);
this.$store.commit("set_filter_val", name);
this.$store.commit("Set_search_val", name);
},
SetviewNav: function (val) {
this.$store.commit("SetviewNav", val);
},
SetHideNav: function (val) {
this.$store.commit("SetHideNav", val);
},
setpage: function (val) {
this.$store.commit("setpage", val);
//var fotter=$('#animate-footer-maps').offset().top;
//$('.j7app-containr').scrollTop(1);
$('.j7app-containr').animate({
scrollTop: 1
}, 800, function(){

});
}
},
watch:{
// whenever question changes, this function will run
pageview: function () {
if(this.view_Nav=='menu'){
this.$refs.horizontal.scrollToIndex(this.pageview + 1);
}
}
},
computed: {
...mapState({
selctedCat : state => state.selctedCat,
view_Nav:state => state.view_Nav,
//db : state => state.db,
cats : state => state.cats,
viewCat : state => state.viewCat,
hide_navbar: state => state.hide_navbar,
pageview : state => state.pageview
})
}
}
</script>

<style scoped>
.frp{display:flex;flex-wrap:wrap;align-content:center;
justify-content: center;align-items:center;}
.span-fixbtn{position:absolute;top:0px;z-index:1;
display: flex;flex-wrap:wrap;justify-content:center;
align-content:center;align-items:center;
}
.span-fixbtn-bg{
position:relative;display: flex;flex-wrap:wrap;justify-content:center;
align-content:center;align-items:center;
border-radius:50%;z-index:1;
}
.j7app-navbar{
/*
background:#f7f6f6;
background-color: rgba(18,19,20,.1);
*/
position: relative;z-index: 1;
display: flex;width: 100%;height: 92px;
max-height: 92px;align-items: center;
background-color:#fff;
color: #272727;
touch-action: pan-x pan-y;
margin-right:auto;margin-left:auto;
padding-right:14px;padding-left:14px;
}
.j7app-navbar-swbox{
position: relative;
height: 72px;max-height: 72px;z-index: 1; 
overflow: hidden;
touch-action: pan-x !important;
margin-right: auto;margin-left: auto;  
padding-right: 7px;padding-left: 7px 
}
.j7app-navbar-item{
display: flex; flex-wrap: wrap;height: 72px; width: 72px;
font-size: 13px;font-weight: 400;text-align: center;
align-content: center;justify-content: center;
background:#fff;
border:1px solid rgba(64, 78, 115,.11);
border-radius:5px;
/*border-radius:50%;*/
user-select: none;cursor: pointer;
margin-left: 12px;
transition-property: transform;
touch-action: pan-x !important;
}
.j7app-navbar-item-name{
display: flex; width: 100%;max-height:42px;font-size:13px; 
align-items: flex-start; justify-content: center;color:#4e4e4e;
font-family: 'Cairo', sans-serif;font-weight:400;
}
.j7app-navbar-item img{
display:flex; justify-content:center;
max-width:55px;height:14px;
border-radius:50%;background:#fff;
}
.j7app-navbar-item.active {
border:1px solid rgba(64, 78, 115,.18);
color:#1e3d60;
}
.j7app-navbar-item:focus {
border-top:1px solid #d5d5d5;
border-bottom:1px solid #a5b4b5;
border-right:1px solid #d5d5d5;
border-left:1px solid #a5b4b5;
color: #1e3d60;
}
.j7app-navbar-item:hover {
border-top:1px solid #d5d5d5;
border-bottom:1px solid #eee;
border-right:1px solid #d5d5d5;
border-left:1px solid #eee;
color: #1a1a1a;
}
.j7app-navbar-item.rippel {
background: linear-gradient(45deg, #faf9f8,#fff);
border-color:#ccc;
color: #1a1a1a;
}
</style>
