<template>
<div class="--scroll_items-- listbox-home-box" style="padding:14px;">
<div v-opacity class="listbox-home-item animate__animated" :id="'animate-item'+(index+1)"
v-if="item.active==true" 
v-for="(item, index) in filteredList" style="position:relative;
height:auto;max-height:auto;" data-animation="animate__fadeInRightBig"
:data-row="index" 
v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, item.code)"
>
<div v-press="(e) => hand(e,item.short_link)" class="fix-item-vid" v-for="(img, index_blur) in item.imgaes"
 v-if="img.type&&img.type.includes('video')"
 style="position:absolute;top:0px;padding-top: 10px;
  padding-bottom: 10px;
width:94%;z-index:1;border-radius:4px;">
<!-- loop=loop autoplay=autoplay  -->
<ItemListVideo :img="img" :code="item.code" :cat_index="index"></ItemListVideo>
</div>
<div class="vlist-home-item-box" style="display:flex; flex-wrap:wrap;width:100%;align-content:center;justify-content:center;
padding-top:10px;padding-bottom:10px;">
<div class="listbox-home-imgbox" v-for="(img, index) in item.imgaes"
 v-if="img.home" 
 style="position:relative;">
<div class="listbox-home-imgkm" v-on:click="show_all_info(item.code,3200)" 
style="position:absolute;top:0px;right:0px;display: flex;flex-wrap:wrap;
height:38px;max-height:38px;width:auto;justify-content: center; align-content:center; z-index:1;">
<div style="display: flex;flex-wrap:wrap;background: rgba(0,0,0,.3);
border-radius: 4px;height:28px;max-height:28px;width:auto;color:rgba(255,255,255,.9);
font-size:12px;font-weight:400;white-space:nowrap;
justify-content: center;align-content:center;padding-left:7px;padding-right:7px;">
<span v-if="item.subtitle">{{item.subtitle}}</span>
<span v-else>مميز بلس</span>
</div>
</div>
<div class="listbox-home-imginfo"
style="position:absolute;top:0px;left:0px;display: flex;flex-wrap:wrap;height:75%;
font-family: 'Noto Kufi Arabic', sans-serif;
width:92px;justify-content: left; align-content:flex-start; z-index:10;padding:7px;">
<ItemListImgInfo :img="img" :code="item.code"
 :guid="item.guid" :type="item.type" :model="item.model"
 :gir="item.gir"
 :cat_index="index"></ItemListImgInfo>
</div>
<div v-press="(e) => hand(e,item.short_link)" class="listbox-home-img"
 style="user-select:none;
position:relative;z-index:0;border-radius:8px;overflow:hidden;">
<img v-if="img.type.includes('image')" 
 v-on:click.self="$router.push('/'+item.short_link),setitem_index(index)" 
 alt="يتم التحميل..." class="lazy sk-light" style="
position:relative;z-index:0;height:100%;width:100%;object-fit: cover;"
draggable="false" :data-src="img.home==true?hosturl+'/media/md'+img.src:''">
</div>
<div class="listbox-home-imgcat" v-on:click="$router.push('/'+item.short_link),setitem_index(index)" 
style="position:relative;bottom:1px;right:0px; display: flex;flex-wrap:wrap;
width:100%;justify-content: center;align-content:center;z-index:1;border-radius:0px 0px 8px 8px;">
<div class="listbox-home-data" style="display:flex;flex-wrap:wrap;align-content:center;">
<div class="listbox-home-data-name" style="width:94%;height:32px;justify-content:right;
font-size:15px;font-weight:400;align-content:center;color:#4e4e4e;white-space:nowrap;">
<span>
{{item.title}}
</span>
<span class="mdi mdi-24px mdi-chevron-left" style="color:#4e4e4e;line-height:32px;"></span>
<span  class="listbox-home-data-mater" style="margin-right:auto;line-height:32px;">
<span v-if="item.area.toString()!=''&&item.area.toString()!='0'" style="font-size:13px;padding-left:5px;">{{item.area}}</span> 
<span v-if="item.area" class="mdi mdi-18px mdi-walk" style="color:#4e4e4e;line-height:32px;"></span>
</span>
</div>
<div class="listbox-home-data-address" style="width:94%;justify-content:right;opacity:0.8;
align-items:center;align-items:center; font-size:13px;font-weight:400;color:#4e4e4e;white-space:nowrap;">
<span style="padding-right:4px;align-items:center;align-items:center;">
{{item.model}} <span v-if="item.model" class="mdi mdi-18px mdi-chevron-left" style="color:#4e4e4e;line-height:18px;"></span>
 {{item.type}} 
</span>
<span class="listbox-home-data-mater" style="margin-right:auto;">
<span style="font-size:14px;">{{item.price}}</span>
<span v-if="!item.price.toString().includes(' ')" style="font-size:14px;padding-right:5px;">{{currency}}</span>
</span>
</div>
</div>
</div>
</div>
</div>
<SoldOutV1 v-if="item.available==false"></SoldOutV1>
<!--<SoldOutV2 v-if="item.available==false"></SoldOutV2>-->
</div>
</div>
</template>
<script>
import {mapState} from 'vuex';
import VueHorizontal from 'vue-horizontal';
import ItemListVideo from '../components/ItemListVideo.vue'
import ItemListImgInfo from '../components/ItemListImgInfo.vue'
import SoldOutV1 from '../components/SoldOutV1.vue'
import SoldOutV2 from '../components/SoldOutV2.vue'

export default {
name: 'ItemFindTags',
components: {
VueHorizontal,
ItemListVideo,ItemListImgInfo,SoldOutV1,SoldOutV2
},
//props: {msg: String},
data(){
return {
tags_link:'',
aos:null,
posts_list:[],
item_like:[],
iframe:null,
width: 0,
scrollWidth: 0,
left: 0,
index: 0,
swaip:0,load:'show',
ref_item_box:''
}
},
methods:{
play_vid:function(val){ 
var player = document.getElementById('playvid'+val);
$('video').each(function() {
if($(this).attr("id")!='playvid'+val){
$(this).get(0).pause();
}
});
if (player.paused){
player.play();
//$('#playvid'+val).data('p-state','play');
$('#playvid-btn-on-'+val).hide();
$('#playvid-btn-off-'+val).hide();
}
else{
player.pause();
$('#playvid-btn-on-'+val).hide();
$('#playvid-btn-off-'+val).show();
}
},
play_vid_mute:function(val){ 
var player = document.getElementById('playvid'+val);
if (player.muted){
player.muted = false;
$('#playvid-volume-on-'+val).show();
$('#playvid-volume-off-'+val).hide();
}
else{
player.muted = true;
$('#playvid-volume-on-'+val).hide();
$('#playvid-volume-off-'+val).show();
}
},
set_story_guid:function(val){ 
this.$store.commit("set_story_guid",val);
},
set_pop_story:function(val){ 
this.$store.commit("set_pop_story",val);
},
hand:function (e,guid){
if(e.type=='press'){
this.set_story_guid(guid);
this.set_pop_story('story');
//animate-item
}
},
set_Like:function (guid){
var ck =this.likes.filter(item =>{
return item.like==guid;   
});
if(ck.length<1)
this.$store.commit("push_likes",guid);
this.$cookies.set('likes',this.likes);
},
formatPrice : function (value) {
var val = parseInt(value);
if (typeof val !== "number") {
return value;
}
if(val>1000&&val<900999){
const formatter = new Intl.NumberFormat('ar-sa', {
notation: 'compact',
minimumFractionDigits: 0,
maximumFractionDigits: 6
});
return formatter.format(val);
}
else if(val>1000000){
const formatter = new Intl.NumberFormat('ar-sa', {
notation: 'compact',
minimumFractionDigits: 6,
maximumFractionDigits: 6
});
return formatter.format(val);
}
else{
return value;
}
},
lazy_update(){
this.$store.commit("lazy_update");
},
onScrollDebounce({ scrollWidth, width, left }) {
//this.left = left;
//this.width = width;
//this.pages = Math.round(scrollWidth / width);
this.scrollWidth = scrollWidth;
this.index = Math.round(left / width);
var num = '' + this.index + '';
var num1 = num.replace('-','');
var num2 = parseInt(num1);
this.setpage(num2);
},
SetViewCat: function (val) {
this.$store.commit("SetViewCat", val);
},
SetviewNav: function (val) {
this.$store.commit("SetviewNav", val);
},
pan_load_down: function (e,val) {
var deltaNowY = 0;
var el = val ;
deltaNowY = e.deltaY;
$('.refresh-item-box').show();
//this.ref_item_box='show';
if(deltaNowY < 50){
$(el).css("-webkit-transform", "translateY(" + deltaNowY + "px)");
$(el).css("transform", "translateY(" + deltaNowY + "px)");
}
else {
setTimeout(function () {
$(el).css("transform", "translate(0px,0px)");
$(el).css("-webkit-transform", "translate(0px,0px)");
}, 100);
}
},
pan_load_end: function (e,val) {
var el = val ;
setTimeout(function () {
$(el).css("transform", "translate(0px,0px)");
$(el).css("-webkit-transform", "translate(0px,0px)");
}, 400)
},
SetPreview : function (val) {
this.$store.commit("SetPreview", val);
},
setitem_index(val){
this.$store.commit("setitem_index", val);
},
Setheight_navbar(val) {
var vh = window.innerHeight;
if (val == 55) {
$('.main-home-page').css('height', vh - 55);
} else {
$('.main-home-page').css('height', vh - 148);
}
this.$store.commit("Setheight_navbar", val);
},
setpage:function (val) {
this.$store.commit("setpage", val);
//$('.nav-icon').addClass('mdi-tag-multiple');
//$('.item-home-nbtn').removeClass('active');
//$('.item-home-nbtn:nth-child(' + (val+1) + ')').addClass('active');
//$('.item-home-nbtn:nth-child(' + (val+1) + ')').find('span').removeClass('mdi-tag-multiple').addClass('mdi-checkbox-marked');
},
handleScroll(event){
var vh = 0;
var scrollTop = event.currentTarget.scrollTop;
   
vh = window.innerHeight;

if(this.hide_navbar == '') {
if(scrollTop > 55) {
this.SetviewNav('');
this.Setheight_navbar(55);     
//this.load = 'hide';
$('.home-nav').css('height', '55px');
$('.main-home-page').css('height', vh - 55);
$('.scroll_items').css('height', vh - 55);
$('.scroll_items').css('maxHeight', vh - 55);
$('.refresh-item-box').hide();
}
else {
this.SetviewNav('menu');
this.Setheight_navbar(55);
//this.load='show';
$('.home-nav').css('height', '148px');
$('.main-home-page').css('height', vh - 148);
$('.scroll_items').css('height', vh - 148);
$('.scroll_items').css('maxHeight', vh - 148);
}
}
},
visibilityChanged:function(isVisible,entry,code) {
if(isVisible&&this.obs_show==''){
$('.listbox-home-box').find('.loaded').removeClass('sk-light');
//console.log(entry);
var animation=$("#"+entry.target.id).data('animation');
if(!entry.target.classList.contains(animation)){
//let domElement = entry.target.id;
entry.target.classList.add(animation);
}
this.show_all_info(code,3200);
}
},
show_all_info:function(code,timeout) {
this.posts_list=[];
$('.room-'+code).show();
setTimeout(function () {
$('.lounge-'+code).show();
}, 800);
setTimeout(function () {
$('.bathroom-'+code).show();
}, 1500);
setTimeout(function () {
$('.room-'+code).fadeOut();
$('.lounge-'+code).fadeOut();
$('.bathroom-'+code).fadeOut();
}, timeout);
},
showInfo:function(className) {
$(className).show();
setTimeout(function () {
$(className).fadeOut();
}, 800);
},
close_pup:function(){ 
this.$store.commit("set_close_pup",'hide');
},
},
created() {
this.tags_link=this.$route.params.link;
this.lazy_update();
$('.j7app-containr').scrollTop(1);
},
destroyed(){
},
mounted(){
this.lazy_update();
this.$store.commit("set_obs_show", 'hide');
if(window.innerWidth>800){
this.$store.commit("set_obs_show", 'hide');
this.tags_link=this.$route.params.link;
}
//$('.listbox-home-box').find('.loaded').removeClass('entered ').removeClass('loaded').removeAttr('data-ll-status');
},
updated(){
//var lazyLoadInstance = new LazyLoad({});
this.lazy_update();
},
computed: {
...mapState({
selctedCat : state => state.selctedCat,
view_Nav:state => state.view_Nav,
update_post_val:state => state.update_post_val,
//db : state => state.db,
cats : state => state.cats,
items : state => state.items,
posts : state => state.posts,
viewCat : state => state.viewCat,
displacement: state => state.displacement,
hosturl : state => state.hosturl,
currency : state => state.currency,
hide_navbar : state => state.hide_navbar,
preview_index : state => state.preview_index,
pageview:state => state.pageview,
preview: state => state.preview,
likes: state => state.likes,
obs_show: state => state.obs_show,
height_navbar : state => state.height_navbar,
search_list: state => state.search_list,
pop_search : state => state.pop_search,
filter_name: state => state.filter_name,
filter_ck: state => state.filter_ck,
filter_val: state => state.filter_val,
filter_count: state => state.filter_count
}),
filteredList(){
this.tags_link=this.$route.params.link;
this.tags_link=this.tags_link.toString().replaceAll("-"," ");
return this.posts.filter(item =>{
//var features=item.features.toString();
//var details=item.details.map(dt =>{
//return dt.name+" "+dt.val+" "+dt.alt;
//});
//return this.tags_link.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v))
return item.title.toString().toLowerCase().includes(this.tags_link.toString().toLowerCase())
||item.subtitle.toString().toLowerCase().includes(this.tags_link.toString().toLowerCase()) 
||item.type.toString().toLowerCase().includes(this.tags_link.toString().toLowerCase())
||item.price.toString().toLowerCase().includes(this.tags_link.toString().toLowerCase())
||item.area.toString().toLowerCase().includes(this.tags_link.toString().toLowerCase())
||item.model.toString().toLowerCase().includes(this.tags_link.toString().toLowerCase())
||item.brand.toString().toLowerCase().includes(this.tags_link.toString().toLowerCase())
||item.brand_type.toString().toLowerCase().includes(this.tags_link.toString().toLowerCase())
||item.gir.toString().toLowerCase().includes(this.tags_link.toString().toLowerCase())
||item.address.toString().toLowerCase().includes(this.tags_link.toString().toLowerCase()) 
||item.tags.toString().toLowerCase().includes(this.tags_link.toString().toLowerCase())
||item.desc.toString().toLowerCase().includes(this.tags_link.toString().toLowerCase())  
||item.details.toString().toLowerCase().includes(this.tags_link.toString().toLowerCase())
||item.features.toString().toLowerCase().includes(this.tags_link.toString().toLowerCase());
});
}
},
watch:{
// whenever question changes, this function will run
tags_link: function (v)
{
this.tags_link=this.$route.params.link;
//$('.item-home-box').find('.lazy').removeClass('entered ').removeClass('loaded').removeAttr('data-ll-status');
$('img.lazy').removeAttr('data-ll-status');
this.lazy_update();
},
pageview: function (){
this.$refs.horizontal_item.scrollToIndex(this.pageview);
}
}
}
</script>
<style>
.swip-item-home .v-hl-snap-center{
display:flex!important;
}
</style>
<style scoped>
.home-item-list-box{
display:flex;flex-wrap:wrap;justify-content:center;align-items:center;margin:0;
box-sizing: content-box;
overflow-x: hidden;
overflow-y: hidden;scrollbar-width:none;
}
.swip-item-home{
display: flex;width:100%;margin-bottom: 0px;
max-width:100%;padding:7px;
margin: 0;padding-bottom: 0px;border: none;
box-sizing: content-box;overflow-x: scroll;overflow-y: hidden;
scroll-snap-type: x mandatory;
scrollbar-width:none;
-webkit-overflow-scrolling: touch;
position:relative;
}
.listbox-home-warp{
display:flex; flex-wrap:wrap;height:fit-content;
width: 100%; position: relative;
touch-action: none;touch-action: pan-x pan-y;
position:relative;font-family: 'Noto Kufi Arabic';font-weight:400;
margin:0;justify-content: center;
}
.listbox-home-box{
display:flex; flex-wrap:wrap;width: 100%; 
/*padding-bottom:100px;*/
touch-action: pan-x pan-y; height: fit-content;
justify-content: center;justify-items:center;
align-items: flex-start;
overflow-y: scroll; overflow-x: hidden; 
scrollbar-color: auto; scrollbar-width: none;
scroll-snap-type: mandatory;
}
.listbox-home-item{
position:relative;
display:flex;flex-wrap:wrap;
justify-content:center;text-align:center;align-items:center;
width:100%;background:#fff;
z-index: 0;cursor: pointer;
touch-action: none; touch-action: pan-x pan-y;
/*box-shadow: 0px 4px 4px 0px #f5f5f5;*/
overflow: hidden;
}
.listbox-home-imgbox{
display: flex; flex-wrap: wrap;
justify-content: center;align-items:center;
max-width:94%;width:100%;height:100%;
overflow: hidden;scrollbar-width: none;
}
.fix-item-vid{
display: flex; flex-wrap: wrap;
justify-content: center;align-items:center;
height:270px;
}
.listbox-home-img{
display: flex; flex-wrap: wrap;
justify-content: center;align-items:center;
height:240px;width:100%;max-width:100%;
/*border-radius:5px 5px 5px 5px;*/
}
.listbox-home-data{
display: flex;
align-items: center;justify-content: center;
scrollbar-width: none;width:100%;height:72px;max-width:94%;
overflow: hidden;z-index: 0;
}
.listbox-home-data-box{
position:relative;width: 100%;max-height:128px;
display: flex;flex-wrap: wrap;
justify-content: center; justify-items:center;
text-align:center;z-index: 0;
}
</style>
<style scoped>
.listbox-home-data-name{
display: flex;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
justify-content: center;align-content:center;
}
.listbox-home-data-name span{
display:flex;justify-content:right;
max-width:75%;
overflow-x:scroll;overflow-y:hidden;
scrollbar-width:none!important;
}
.listbox-home-data-address{
display: flex;flex-wrap:wrap;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
justify-content: center;color:#000;
align-content:center;
}
.listbox-home-data-address span{
display:flex;flex-wrap:wrap;justify-content:right;
overflow-x:scroll;overflow-y:hidden;scrollbar-width:none;
}

.listbox-home-data-name img{
padding-right:5px;padding-left:5px;
}

.listbox-home-data-info{
display: flex;flex-wrap:wrap;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
color:#999;justify-content: center;align-content:center;
}
.listbox-home-data-info div{
display:flex;flex-wrap:wrap;justify-content:center;
font-size:12px;white-space:nowrap;align-content:center;
color:#999;scrollbar-width:none;
}
.listbox-home-data-info span{
display:flex;flex-wrap:wrap;justify-content:right;
font-size:12px;white-space:nowrap;padding-right:2px;padding-left:2px;
color:#999;align-content:center;
}
.listbox-home-data-info a{
display:flex;flex-wrap:wrap;
font-size:12px;white-space:nowrap;padding-right:2px;padding-left:2px;
color:#555;justify-content:right;align-content:center;
}
.listbox-home-data-price{
display: flex;flex-wrap:wrap;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
color: #000; justify-content: center;align-content:center;
}
.listbox-home-data-price span{
display:flex;flex-wrap:wrap;justify-content:right;
font-size:12px;white-space:nowrap;
color:#555;align-content:center;
margin:auto;
}
</style>
<style scoped>

@media (min-width: 200px) and (max-width: 575px){
.swip-item-home{}
.listbox-home-item{
width:100%;height:360px;max-width:96%;
overflow: hidden;
border:1px solid #bfcacb;box-shadow: 0px 2px 2px 0px #f5f5f5;
border-radius:12px;
margin-top:7px;margin-bottom:7px;
}
/*.listbox-home-item::before{
content: "";
position: absolute;
width:100px;
background-image: linear-gradient(180deg,#f27788,#4e4e4e);
height: 130%;
animation: rotBGimg 3s linear infinite;
transition: all .2s linear;
}*/
.listbox-home-data{max-width:100%;}
.listbox-home-img{height:250px;}
}

/*@media (min-width: 600px){
.listbox-home-item{
width:50%;height:320px;max-width:50%;
overflow: hidden;
}
.listbox-home-img{height:320px;}
.listbox-home-data{height:100px;max-width:100%;}
}*/

@media (min-width: 600px) and (max-width: 800px){
.listbox-home-item{
width:50%;height:320px;
max-width:48%;
margin-left:2%;
margin-top:10px!important;
margin-bottom:10px!important;
overflow: hidden;
border:1px solid #bfcacb;box-shadow: 0px 2px 2px 0px #f5f5f5;
border-radius:12px;
}
.listbox-home-img{height:250px;}
.listbox-home-data{height:100px;max-width:100%;}
}

@media (min-width: 800px) and (max-width: 1000px){
.listbox-home-item{
width:50%;height:320px;
max-width:48%;
margin-left:2%;
margin-top:10px!important;
margin-bottom:10px!important;
overflow: hidden;
border:1px solid #bfcacb;box-shadow: 0px 2px 2px 0px #f5f5f5;
border-radius:12px;
}
.listbox-home-img{height:250px;}
.listbox-home-data{height:72px;max-width:94%;}
.listbox-home-box{justify-content:right;}
}

@media (min-width: 1000px) and (max-width: 1400px){
.listbox-home-item{
width:33.33%;height:320px;max-width:32.33%;
margin-left:1%;
margin-top:10px!important;
margin-bottom:10px!important;
overflow: hidden;
border:1px solid #bfcacb;box-shadow: 0px 2px 2px 0px #f5f5f5;
border-radius:12px;
}
.listbox-home-data{height:72px;max-width:94%;}
.listbox-home-box{justify-content:right;}
.listbox-home-img{height:250px;}
}

@media (min-width: 1400px){
.fix-item-bg{filter:blur(5px)!important;}
.listbox-home-item{
width:20%;height:320px;margin-left:0.5%;
max-width:19.5%;border-radius:12px;margin-top:7px;margin-bottom:7px;
overflow: hidden;border:1px solid #ddd;
box-shadow: 0px 2px 2px 0px #f5f5f5;
}
.listbox-home-data{height:72px;max-width:94%;}
.listbox-home-img{height:250px;}
.listbox-home-box{justify-content:right;}
}
@keyframes rotBGimg{
0%{transform:rotate(0)}
to{transform:rotate(360deg)}
}
</style>