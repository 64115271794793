<template>
<div class="home-tags-box" style="">
<a v-on:click="$router.push('/tags/'+'سوم')">
<span>#</span><span>ع السوم</span>
</a>
<a v-on:click="$router.push('/tags/'+'قولد')">#قولد</a>
<a v-on:click="$router.push('/tags/'+'سوزوكي')">#سوزوكي</a>
<a v-on:click="$router.push('/tags/'+'دوكاتي')">#دوكاتي</a>
<a v-on:click="$router.push('/tags/'+'هارلي')">#هارلي</a>
<a v-on:click="$router.push('/tags/'+'كاواساكي')">#كاواساكي</a>
<a v-on:click="$router.push('/tags/'+'موقف')">#موقف</a>
<a v-on:click="$router.push('/tags/'+'هايبوزا')">#هايبوزا</a>
<a v-on:click="$router.push('/tags/'+'امريكي')">#امريكي</a>
<a v-on:click="$router.push('/tags/'+'كوبي')">#كوبي ون</a>
<a v-on:click="$router.push('/tags/'+'جوال')">#جوال</a>
<a v-on:click="$router.push('/tags/'+'حماية')">#حماية</a>
<a v-on:click="$router.push('/tags/'+'مسكات')">#مسكات</a>
<a v-on:click="$router.push('/tags/'+'فل')">#فل سستم</a>
<a v-on:click="$router.push('/tags/'+'مرتبة')">#مرتبة</a>
<a v-on:click="$router.push('/tags/'+'فلتر')">#فلتر</a>
<a v-on:click="$router.push('/tags/'+'جوال')">#USB</a>
<a v-on:click="$router.push('/tags/'+'بلوتوث')">#بلوتوث</a>
<a v-on:click="$router.push('/tags/'+'كوبي')">#اصلية</a>
<a v-on:click="$router.push('/tags/'+'كاسم')">#كاسم</a>
<a v-on:click="$router.push('/tags/'+'هدروليك')">#هدروليك</a>
<a v-on:click="$router.push('/tags/'+'بلاك')">#بلاك</a>
<a v-on:click="$router.push('/tags/'+'احمر')">#احمر</a>
<a v-on:click="$router.push('/tags/'+'اصفر')">#اصفر</a>
<a v-on:click="$router.push('/tags/'+'ABS')">#ABS</a>
<a v-on:click="$router.push('/tags/'+'750')"><span>#</span>750</a>
<a v-on:click="$router.push('/tags/'+'600')"><span>#</span>600</a>
<a v-on:click="$router.push('/tags/'+'F6B')">#F6B</a>
<a v-on:click="$router.push('/tags/'+'ld')">#Gold Wing</a>
<a v-on:click="$router.push('/tags/'+'2021')"><span>#</span>2021</a>
<a v-on:click="$router.push('/tags/'+'2020')"><span>#</span>2020</a>
<a v-on:click="$router.push('/tags/'+'2017')"><span>#</span>2017</a>
<a v-on:click="$router.push('/tags/'+'2017')"><span>#</span>2017</a>
<a v-on:click="$router.push('/tags/'+'2015')"><span>#</span>2015</a>
<a v-on:click="$router.push('/tags/'+'2013')"><span>#</span>2013</a>
</div>
</template>
<script>
import { mapState } from 'vuex';
export default {
name: 'ViewTags',
components: {
},
//props: {msg: String},
data(){
return{
}
},
methods:{

},
watch:{

},
computed: {
...mapState({
setup: state => state.setup,
//view_Nav:state => state.view_Nav,
})
}
}
</script>
<style scoped>
.home-tags-box{
/*background: linear-gradient(to bottom right, #fff 50%,#f1f1f1 20%,#fdfdfd 30%);*/
position:relative;display: flex;flex-wrap:wrap;
width:100%;height: auto;color:#4e4e4e;
position:relative;z-index:0;
/*padding:14px;*/
padding:18px;margin-right:auto;margin-left:auto;
font-family: 'Cairo', sans-serif;font-weight:400;
cursor:pointer;justify-content: right;
}
.home-tags-box a{
display:flex;flex-wrap:wrap;align-content:center;justify-content:center;
font-size:16px;color:#4e4e4e;height:50px;border-radius:8px;min-width:92px;
margin-left:12px;margin-bottom:12px;
border-top:1px solid #ccc;background:#fff;
border-right:1px solid #ccc;
border-bottom:1px solid #cdcdcd;
border-left:1px solid #ccc;
}
.home-tags-box a:link{color:#4e4e4e;}
.home-tags-box a:visited{color:#4e4e4e;}

@media (min-width: 200px) and (max-width: 575px){
.home-tags-box{
justify-content:center;
}
}
@media (min-width: 800px) and (max-width: 1200px){

}
@media (min-width: 1200px){

}

</style>