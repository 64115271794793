<template>
<div class="pop-som-home-box" style="font-family: 'Noto Kufi Arabic', sans-serif">
<div class="animate__animated animate__fadeInLeft animate__faster" v-if="temp_som.length==0"
style="display: flex; flex-wrap:wrap;
height:auto;color:#fff;width:100%;justify-content:center; align-content: center;margin:auto;
padding:18px;max-width:1024px;">
<img src="/img/load2.gif" 
style="display:flex;max-height:100px;overflow:hidden;" />
<span style="margin-top:14px;display:flex;width:100%;height:36px;font-size:18px;color:#fff;justify-content:center;">
يرجى الانتظار..
</span>
</div>
<div class="animate__animated animate__fadeInLeft animate__faster"
 v-if="temp_som.length>0"
style="font-family: 'Noto Kufi Arabic', sans-serif;font-size:15px;display: flex; flex-wrap:wrap;
height:auto;color:#fff;border-radius:14px;
width:100%;justify-content:center; align-content: center;margin:auto;
padding:18px;max-width:1024px;">
<div class="home-pop-som-form" style="" v-if="send_done==false&&temp_som[0].som_state!='done'">
<div class="home-pop-som-form-box" style="margin-top:24px;margin-right:auto;margin-left:auto;color: #4e4e4e;
width:100%;">
<span style="font-size:24px;color:#fff;">الدراجة العنيفة</span>
</div>
<div class="home-pop-som-form-box" style="margin-top:14px;margin-right:auto;margin-left:auto;color: #4e4e4e;
width:100px;height:100px;max-height:100px;overflow:hidden;border-radius:50%;
border:3px solid #ddd;">
<img :src="hosturl+'/media/sm'+temp_som[0].favicon" 
style="display:flex;width:100px;height:100px;object-fit:cover;overflow:hidden;" />
</div>
<div style="height: auto;margin-top:14px;display: flex; flex-wrap:wrap; align-content: center;justify-content: center !important; width: 100%;touch-action: pan-y !important; ">
<span style="font-size:21px;color:#fff;">{{temp_som[0].title}}</span>
</div>
<div v-if="send_done==false" class="home-pop-som-form-box" 
style="margin-top:7px;margin-right:auto;margin-left:auto;color:#fff;font-size:21px;">
<span style="padding-right:6px;">السومه : </span>
<span>{{temp_som[0].som_price}}</span>
<span style="padding-right:6px;">ر.س</span>
</div>
<div v-if="send_done==false" class="home-pop-som-form-box" 
style="margin-right:auto;margin-left:auto;">
<div class="home-pop-som-form-dark-btn" v-on:click="view_post()"
 style="margin-top:24px;margin-bottom:14px;width:144px;margin-left:5px;">
<span style="background:#35af47;display: flex;flex-wrap:wrap;align-content: center;
 width:100%;height:55px;justify-content: center !important;border-radius:24px;opacity:0.95;">
<span style="font-size: 18px;">قبول</span>
</span>
</div>
<div class="home-pop-som-form-dark-btn" v-on:click="send_done=true"
 style="margin-top:24px;margin-bottom:14px;width:144px;margin-right:5px;">
<span style="background:#e94831;display: flex;flex-wrap:wrap;align-content: center;
width:100%;height:55px;justify-content: center !important;border-radius:24px;opacity:0.95;">
<span style="font-size: 18px;">رفض</span>
</span>
</div>
</div>
</div>
<div class="home-pop-som-form animate__animated animate__fadeInLeft animate__faster" style="" v-if="send_done==true">
<div v-if="send_done==true" style="margin-top:14px;height: auto;display: flex; flex-wrap:wrap; align-content: center;justify-content: center !important;
 width: 100%;touch-action: pan-y !important;">
<span style="width:92px;height:92px;border-radius:50%;background:rgba(82, 180, 74,0.1);display: flex; flex-wrap:wrap; align-content: center;justify-content: center; ">
<img src="/img/done.svg" style="display:flex;justify-content:center;width:100%;height:100%;object-fit:contain;" />
</span>
</div>
<div class=""
v-if="send_done==true" style="font-size: 16px;padding: 14px 0px 14px 0px;
display: flex; flex-wrap:wrap; align-content: center;height: auto;
justify-content: center !important; width: 100%; touch-action: pan-y !important; ">
<span class="pop-som-rap" style="height:36px;color:#fff;font-size: 21px;">تم ارسال طلبك بنجاح..</span>
</div>
</div>
<div class="home-pop-som-form animate__animated animate__fadeInLeft animate__faster" style="" v-if="temp_som[0].som_state=='done'">
<div style="margin-top:14px;height: auto;display: flex; flex-wrap:wrap; align-content: center;justify-content: center !important;
 width: 100%;touch-action: pan-y !important;">
<span style="width:92px;height:92px;border-radius:50%;background:rgba(82, 180, 74,0.1);display: flex; flex-wrap:wrap; align-content: center;justify-content: center; ">
<img src="/img/done.svg" style="display:flex;justify-content:center;width:100%;height:100%;object-fit:contain;" />
</span>
</div>
<div style="font-size: 16px;padding: 14px 0px 14px 0px;
display: flex; flex-wrap:wrap; align-content: center;height: auto;
justify-content: center !important; width: 100%; touch-action: pan-y !important; ">
<span class="pop-som-rap" style="height:48px;color:#fff;font-size: 21px;">تم قبول السومة مسبقا..</span>
</div>
</div>
</div>
</div>
</template>
<script>
import { mapState} from "vuex";
import axios from 'axios';
export default {
name: 'SomAccpet',
components: {},
//props: ['text'],
data(){
return {
link:this.$route.params.link,
send_done:false,
cksom:false,
temp_som:[],
popup:''  
}
},
mounted(){
this.ck_som();
},
methods: {
//cksom.aspx
ck_som:function () {
axios.get(this.api_url+'get_temp_som.aspx?som='+this.dash_key+'&link='+this.link)
.then((response) => {
if(response.data.status=='ok'){
this.temp_som=response.data.temp_som;
this.cksom=true;
}
else{
this.Swal_Error(response.data.status);
}
},(error) => {
this.Swal_Error(error);
});
},
view_post:function (){
axios.get(this.api_url+'som_accept.aspx?som='+this.dash_key
+'&link='+this.temp_som[0].temp_link
//+'&json='+second+'bc5q'+minute+'7zm'
)
.then((response) => {
if(response.data.status=='ok'){
this.send_done=true;
//this.Swal_Success('تم ارسال طلبك بنجاح');
}
else{
this.Swal_Error(response.data.status);
}
},(error) => {
this.Swal_Error(error);
});
},
Swal_Success:function (val){
this.$store.commit("Swal_Success", val);
},
Swal_Error:function (val){
this.$store.commit("Swal_Error", val);
},
isNumberKey: function (event){
//  var regex = new RegExp(/^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/);
/*  if (regex.test(this.input_mobile)) {this.mobile_ck = true;}*/
var charCode = (event.which) ? event.which : event.keyCode
if (charCode > 31 && (charCode < 48 || charCode > 57))
{
this.input_phone = this.input_phone.toString().replace(/[^0-9]/g, '');
//this.input_mobile = this.input_mobile.slice(0, -1);
return this.Swal_Error('الحروف غير مسموح بها !!');
}
return true;
},
btn_show:function (val){
$(val).hide();
$(val).show();
$(val).removeClass("animate__animated animate__fadeOutLeft animate__faster");
$(val).addClass("animate__animated animate__fadeInLeft animate__faster");
},
},
computed: {
...mapState({
hosturl : state => state.hosturl,
api_url : state => state.api_url,
dash_key : state => state.dash_key,
pop_som : state => state.pop_som,
})
},
watch:{

}
}
</script>
<style scoped>
.pop-som-rap{
position:relative;display:flex;flex-wrap:wrap;width:100%;
justify-content:center;align-content:center;
}
.pop-som-home-box{
background:none;z-index:0;position:relative;cursor:pointer;
display: flex; flex-wrap: wrap;height:auto;width:100%;
justify-content:center;align-content:center;padding-right:18px;padding-left:18px;
color:#4e4e4e;font-weight:500;white-space: pre-wrap;font-size:16px;text-align:center;
}
.home-pop-som-info{
position:relative; display: flex; flex-wrap:wrap;height:auto;
width: 100%;justify-content:center; align-content: center;
}
.home-pop-som-form{
position:relative;display: flex;flex-wrap:wrap;height:auto;
width: 100%;justify-content:center; align-content: center;
}
.home-pop-som-form-box{
position:relative;display:flex;flex-wrap:wrap;width: 100%;
align-content: center;justify-content: center !important; 
}
input[type="text"] {
background-color: rgb(252, 252, 251);
min-height:64px;
border:1px solid #aaaaaa;
border-radius: 32px;
font-size:16px;
outline: none !important;
overflow: hidden !important;
text-align:center;
}
.home-pop-som-form-dark-btn{
display: flex;flex-wrap:wrap;
align-content: center;justify-content: center !important;
color:#fff;margin-right:auto;margin-left:auto;
border-radius:32px;height:72px;max-height:72px;
}
</style>
