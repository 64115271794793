<template>
<div class="swip-item-home"
style="height:auto;">
<div class="menu-item-box listbox-home-warp" style="">
<div class="--scroll_items-- listbox-home-box" style="background:#fff;">
<div v-opacity class="listbox-home-item animate__animated" :id="'animate-item'+(index+1)" 
v-for="(item, index) in filteredList" v-if="index<=3&&item.active==true"
style="height:auto;max-height:auto;"
 data-animation="animate__fadeInRightBig"
:data-row="index"
v-observe-visibility="visibilityChanged">
<!--<router-link to="/item" replace></router-link>-->
<!--hosturl+'/media/sm'+img.src-->
<div style="display:flex; flex-wrap:wrap;width:100%;align-content:center;justify-content:center;
padding-top:10px;padding-bottom:10px;">
<div class="listbox-home-imgbox" v-for="(img, index) in item.imgaes" v-if="img.home"
 style="position:relative;
">
<div class="listbox-home-imgkm" 
style="position:absolute;top:0px;right:0px;display: flex;flex-wrap:wrap;
height:38px;max-height:38px;width:auto;justify-content: center; align-content:center; z-index:1;">
<div style="display: flex;flex-wrap:wrap;background: rgba(0,0,0,.3);
border-radius: 4px;height:28px;max-height:28px;width:auto;color:rgba(255,255,255,.9);
font-size:12px;font-weight:400;white-space:nowrap;
justify-content: center;align-content:center;padding-left:7px;padding-right:7px;">
<span v-if="item.subtitle>414">{{find_like_item=item.tags}}</span>
<span  v-if="item.subtitle">{{item.subtitle}}</span>
<span v-else>مميز بلس</span>
</div>
</div>
<div class="listbox-home-imgcat" v-on:click="setitem_index(index,item.short_link)" 
style="background: rgba(0,0,0,.4);position:absolute;bottom:1px;right:0px; display: flex;flex-wrap:wrap;
width:100%;justify-content: center;align-content:center;z-index:1;border-radius:0px 0px 8px 8px;">
<div class="listbox-home-data" style="display: flex;flex-wrap:wrap;align-content:center;">
<div class="listbox-home-data-name" style="width:94%;height:32px;justify-content:right;
font-size:15px;font-weight:400;align-content:center;color:#f7f6f6;white-space:nowrap;">
<span>
{{item.title}}
</span>
<span class="mdi mdi-24px mdi-chevron-left" style="color:#eee;line-height:32px;"></span>
<span class="listbox-home-data-v-price" style="margin-right:auto;">
{{item.price}} 
<span v-if="!item.price.toString().includes(' ')" style="font-size:14px;padding-left:5px;padding-right:5px;">{{currency}}</span>
</span>
</div>
</div>
</div>
<div class="listbox-home-img" v-on:click="setitem_index(index,item.short_link)" style="user-select:none;
position:relative;z-index:0;border-radius:8px;overflow:hidden;">
<img v-if="img.type.includes('image')" v-on:click.self="setitem_index(index,item.short_link)" 
 alt="يتم التحميل..." class="lazy sk-light" style="
position:relative;z-index:0;height:100%;width:100%;object-fit: cover;"
draggable="false" :data-src="img.home==true?hosturl+'/media/md'+img.src:''">
<img v-if="img.type.includes('video')" v-on:click.self="setitem_index(index,item.short_link)" 
 alt="يتم التحميل..." class="lazy sk-light" style="
position:relative;z-index:0;height:100%;width:100%;object-fit: cover;"
draggable="false" :data-src="hosturl+'/media/md'+img.poster">
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</template>
<script>
import {mapState} from 'vuex';
import VueHorizontal from 'vue-horizontal';

export default {
name: 'ItemListBottomV2',
components: {VueHorizontal},
props: ['title','tags','address','model','brand','price'],
data(){
return {
find_like_item:'',
aos:null,
iframe:null,
width: 0,
scrollWidth: 0,
left: 0,
index: 0,
swaip:0,load:'show',
ref_item_box:''
}
},
methods:{
set_update_post_val: function (val) {
this.$store.commit("set_update_post_val", val);
},
setitem_index(val,guid){
this.$store.commit("setitem_index", val);
//this.$router.go('/'+guid);
this.$router.push({name: 'HomeItemView', params: { guid: guid }});
this.set_update_post_val(guid);
//$('.j7app-containr').scrollTop(1);
$('.j7app-containr').animate({scrollTop: 1}, 800, function(){});
},
formatPrice : function (value) {
var val = parseInt(value);
if (typeof val !== "number") {
return value;
}
if(val>1000&&val<900999){
const formatter = new Intl.NumberFormat('ar-sa', {
notation: 'compact',
minimumFractionDigits: 0,
maximumFractionDigits: 6
});
return formatter.format(val);
}
else if(val>1000000){
const formatter = new Intl.NumberFormat('ar-sa', {
notation: 'compact',
minimumFractionDigits: 6,
maximumFractionDigits: 6
});
return formatter.format(val);
}
else{
return value;
}
},
lazy_update(){
this.$store.commit("lazy_update");
},
visibilityChanged:function(isVisible, entry) {
if(isVisible){
$('.listbox-home-box').find('.loaded').removeClass('sk-light');
//console.log(entry.target.id);
var animation=$("#"+entry.target.id).data('animation');
if(!entry.target.classList.contains(animation)){
//let domElement = entry.target.id;
entry.target.classList.add(animation);
}
}
},
showInfo:function(className) {
$(className).show();
setTimeout(function () {
$(className).fadeOut();
}, 800);
}
},
created() {
this.lazy_update();
//window.addEventListener('scroll', this.scrollFunction);
},
destroyed(){
//window.removeEventListener('scroll', this.scrollFunction);
},
mounted(){
// address | type
this.$store.commit("GetPostsMore",this.dash_key
+"&model="+this.model.toString().toLowerCase()
+"&brand="+this.brand.toString().toLowerCase()
+"&price="+this.price.toString().toLowerCase()
+"&tags="+this.tags.toString().toLowerCase()
);
this.lazy_update();
},
updated() {
//var lazyLoadInstance = new LazyLoad({});
this.lazy_update();
},
computed: {
...mapState({
selctedCat : state => state.selctedCat,
view_Nav:state => state.view_Nav,
update_post_val:state => state.update_post_val,
//db : state => state.db,
posts : state => state.posts,
posts_more : state => state.posts_more,
viewCat : state => state.viewCat,
displacement: state => state.displacement,
hosturl : state => state.hosturl,
dash_key : state => state.dash_key,
currency : state => state.currency,
hide_navbar : state => state.hide_navbar,
preview_index : state => state.preview_index,
pageview:state => state.pageview,
preview: state => state.preview,
search_val: state => state.search_val,
height_navbar : state => state.height_navbar

}),
filteredList(){
//return this.posts_more;
return this.posts.filter((item, index) =>{
return item.model.toString().toLowerCase().includes(this.model.toLowerCase()) 
//|| item.address.toLowerCase().includes(this.address.toLowerCase()) 
||item.brand.toString().toLowerCase().includes(this.brand.toLowerCase()) 
||item.tags.toString().toLowerCase()==this.tags.toLowerCase() 
||item.type.toString().toLowerCase().includes(this.brand.toLowerCase()) 
||item.price.toString().toLowerCase().includes(this.brand.toLowerCase()) 
});
}
},
watch:{
// whenever question changes, this function will run
search_val: function (v)
{
//$('.item-home-box').find('.lazy').removeClass('entered ').removeClass('loaded').removeAttr('data-ll-status');
$('img.lazy').removeAttr('data-ll-status');
this.lazy_update();
},
pageview: function ()
{
this.$refs.horizontal_item.scrollToIndex(this.pageview);
}
}
}
</script>
<style scoped>
.home-item-list-box{
display:flex;flex-wrap:wrap;justify-content:center;align-items:center;margin:0;
box-sizing: content-box;
overflow-x: hidden;
overflow-y: hidden;scrollbar-width:none;
}
.swip-item-home{
display: flex;width:100%;margin-bottom: 0px;
max-width:100%;padding:7px;
margin: 0;padding-bottom: 0px;border: none;
box-sizing: content-box;overflow-x: scroll;overflow-y: hidden;
scroll-snap-type: x mandatory;
scrollbar-width:none;
-webkit-overflow-scrolling: touch;
position:relative;
}
.listbox-home-warp{
display:flex; flex-wrap:wrap;height:fit-content;
width: 100%; position: relative;
touch-action: none;touch-action: pan-x pan-y;
position:relative;font-family: 'Noto Kufi Arabic';font-weight:400;
margin:0;justify-content: center;
scroll-snap-type: x mandatory;
scrollbar-width:none;
-webkit-overflow-scrolling: touch;
}
.listbox-home-box{
display:flex; flex-wrap:wrap;width: 100%; 
/*padding-bottom:100px;*/
touch-action: pan-x pan-y; height: fit-content;
justify-content: center;
align-items: flex-start;
overflow-y: scroll; overflow-x: hidden; 
scrollbar-color: auto; scrollbar-width: none;
scroll-snap-type: mandatory;
}
.listbox-home-item{
position:relative;
display:flex;flex-wrap:wrap;
justify-content:center;text-align:center;align-items:center;
width:100%;
z-index: 0;cursor: pointer;
touch-action: none; touch-action: pan-x pan-y;
/*box-shadow: 0px 4px 4px 0px #f5f5f5;*/
overflow: hidden;
/*border-radius:5px 5px 5px 5px;*/
/*border-bottom:1px solid #ededed;*/

}
.listbox-home-imgbox{
display: flex; flex-wrap: wrap;
justify-content: center;align-items:center;
max-width:94%;width:100%;height:100%;
overflow: hidden;scrollbar-width: none;
}
.listbox-home-img{
display: flex; flex-wrap: wrap;
justify-content: center;align-items:center;
height:240px;width:100%;max-width:100%;
/*border-radius:5px 5px 5px 5px;*/
}
.listbox-home-data{
display: flex;
align-items: center;justify-content: center;
scrollbar-width: none;width:100%;height:72px;max-width:94%;
overflow: hidden;z-index: 0;
}
.listbox-home-data-box{
position:relative;width: 100%;max-height:128px;
display: flex;flex-wrap: wrap;
justify-content: center; justify-items:center;
text-align:center;z-index: 0;
}
</style>
<style scoped>
.listbox-home-data-name{
display: flex;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
justify-content: center;align-content:center;
}
.listbox-home-data-name span{
display:flex;justify-content:right;
max-width:75%;
overflow-x:scroll;overflow-y:hidden;scrollbar-width:none;
}
.listbox-home-data-address{
display: flex;flex-wrap:wrap;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
justify-content: center;color:#000;
align-content:center;
}
.listbox-home-data-address span{
display:flex;flex-wrap:wrap;justify-content:right;
font-size:13px;
overflow-x:scroll;overflow-y:hidden;scrollbar-width:none;
}

.listbox-home-data-name img{
padding-right:5px;padding-left:5px;
}

.listbox-home-data-info{
display: flex;flex-wrap:wrap;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
color:#999;justify-content: center;align-content:center;
}
.listbox-home-data-info div{
display:flex;flex-wrap:wrap;justify-content:center;
font-size:12px;white-space:nowrap;align-content:center;
color:#999;scrollbar-width:none;
}
.listbox-home-data-info span{
display:flex;flex-wrap:wrap;justify-content:right;
font-size:12px;white-space:nowrap;padding-right:2px;padding-left:2px;
color:#999;align-content:center;
}
.listbox-home-data-info a{
display:flex;flex-wrap:wrap;
font-size:12px;white-space:nowrap;padding-right:2px;padding-left:2px;
color:#555;justify-content:right;align-content:center;
}
.listbox-home-data-price{
display: flex;flex-wrap:wrap;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
color: #000; justify-content: center;align-content:center;
}
.listbox-home-data-price span{
display:flex;flex-wrap:wrap;justify-content:right;
font-size:12px;white-space:nowrap;
color:#555;align-content:center;
margin:auto;
}
</style>
<style scoped>

@media (min-width: 200px) and (max-width: 575px){
.listbox-home-item{
width:100%;height:240px;max-width:100%;
overflow: hidden;
}
.listbox-home-data{height:50px;max-width:100%;}
.listbox-home-img{height:240px;}
}

@media (min-width: 600px) and (max-width: 800px){
.listbox-home-item{
width:50%;height:240px;max-width:50%;
overflow: hidden;
}
.listbox-home-img{height:240px;}
.listbox-home-data{height:50px;max-width:100%;}
}
@media (min-width: 800px) and (max-width: 1000px){
.listbox-home-item{
width:32%;height:240px;max-width:100%;
overflow: hidden;
}
.listbox-home-img{height:240px;}
.listbox-home-data{height:72px;max-width:100%;}
.listbox-home-box{justify-content:right;}
}
@media (min-width: 1000px) and (max-width: 1200px){
.listbox-home-data-v-price {}
.listbox-home-item{
width:33.33%;height:240px;max-width:100%;
overflow: hidden;
}
.listbox-home-data{height:72px;max-width:100%;}
.listbox-home-box{justify-content:right;}
.listbox-home-img{height:240px;}
}
@media (min-width: 1200px) and (max-width: 1400px){
.listbox-home-data-v-price {}
.listbox-home-item{
width:25%;height:240px;max-width:100%;
overflow: hidden;
}
.listbox-home-data{height:72px;max-width:100%;}
.listbox-home-box{justify-content:right;}
.listbox-home-img{height:240px;}
}
@media (min-width: 1400px){
.listbox-home-item{
width:25%;height:240px;max-width:50%;
overflow: hidden;
}
.listbox-home-data{height:72px;max-width:100%;}
.listbox-home-img{height:240px;}
.listbox-home-box{justify-content:right;}
}
</style>