<template>
<div class="pop-som-home-box" style="">
<div class="animate__animated animate__fadeInLeft animate__faster" style="font-family: 'Noto Kufi Arabic', sans-serif;font-size:15px;display: flex; flex-wrap:wrap;
height:auto;color:#353c45;background:#fff;border-radius:14px;
width:100%;justify-content:center; align-content: center;margin:auto;
padding:18px;max-width:1024px;">
<div class="home-pop-som-info" v-if="send_done==false" >
<div style="height:auto;display: flex; flex-wrap:wrap; align-content: center;justify-content: center !important; width: 100%;touch-action: pan-y !important; ">
<span style="font-size:32px;color:#353c45;"> اضف سومتك الان ! </span>
</div>
<div style="height: auto;margin-top:5px;display: flex; flex-wrap:wrap; align-content: center;justify-content: center !important; width: 100%;touch-action: pan-y !important; ">
<span v-if="som_end>0&&cksom==true" style="font-size:16px;color:#353c45;"> السوم وصل {{formatPrice(som_end)}} ريال </span>
<span v-if="som_end==0&&cksom==true" style="font-size:16px;color:#353c45;"> بداية السوم من {{som_start}} ريال </span>
</div>
<div v-if="send_done==false" class="home-pop-som-form-box" 
style="font-size:16px;margin-top:5px;margin-right:auto;margin-left:auto;color: #4e4e4e;width:100%;">
<span>واقل مبلغ لزيادة السوم</span>
<span style="padding-right:6px;">{{som_plus}}</span>
<span style="padding-right:6px;">ريال</span>
</div>
</div>
<!--<div style="margin-top:14px;height: 45px; font-size: 24px;display: flex; flex-wrap:wrap; align-content: center;justify-content: center !important; width: 100%;touch-action: pan-y !important; ">
<img src="img/sultann-x-logo-small.png" style="display:flex;max-height:45px;object-fit:contain;overflow:hidden;" />
</div>-->
<div class="home-pop-som-form" style="" v-if="send_done==false">
<!--<div v-if="send_done==false" class="home-pop-som-form-box" style="margin-top:18px;color: #4e4e4e;">
<input v-model="input_name" type="text" lang="en" maxlength="50"
placeholder="الاسم" style="min-width:255px;font-size: 16px;text-align:center;"
:class="code_ck==true ? 'in_code val-success':'in_code val-error'">
</div>-->
<div class="home-pop-som-form-box" style="position:relative;margin-top:24px;margin-right:auto;margin-left:auto;color: #4e4e4e;">
<input v-model="input_phone" v-on:keypress="isNumberKey" type="text" lang="en-US" inputmode="numeric" maxlength="10"
placeholder="رقم الجوال" style="min-width:288px;font-size: 16px;text-align:center;"
:class="code_ck==true ? 'in_code val-success':'in_code val-error'">
<span v-if="mobile_valid=='yes'" class="mdi mdi-24px mdi-checkbox-marked-circle-outline" 
style="color:#52b44a;position:absolute;top:18px;margin-right:220px;"></span>
</div>
<div v-if="send_done==false" class="home-pop-som-form-box" style="position:relative;
margin-top:18px;margin-right:auto;margin-left:auto;color: #4e4e4e;">
<input v-model="input_val" v-on:keypress="isNumberKey" type="text"  lang="en-US" inputmode="numeric"
maxlength="7"
placeholder="سومتك كم" style="min-width:288px;font-size: 16px;text-align:center;"
:style="som_valid=='yes'? 'border-color:#88bf84;':'border-color:#aaaaaa;'">
<span v-if="som_valid=='yes'&&input_val>0" class="mdi mdi-24px mdi-checkbox-marked-circle-outline" 
style="color:#52b44a;position:absolute;top:18px;margin-right:220px;"></span>
<span v-if="som_valid=='no'&&input_val>0" class="mdi mdi-24px mdi-alert-octagram"
style="color:#d96c77;position:absolute;top:18px;margin-right:220px;"></span>
</div>
<div v-if="som_valid=='yes'&&input_val>0" class="home-pop-som-form-box" style="
align-items:center;margin-top:18px;margin-right:auto;margin-left:auto;
color: #4e4e4e;font-size:22px;height:32px;">
<span  class="mdi mdi-24px mdi-check-circle" 
style="color:#52b44a;"></span>
<span style="padding-right:6px;">سومتك</span>
<span style="padding-right:6px;">= {{somtk}}</span>
<span style="padding-right:6px;">ريال</span>
</div>
<div v-if="som_valid=='yes'&&input_val>0" class="home-pop-som-form-box" style="
align-items:center;margin-top:18px;margin-right:auto;margin-left:auto;
color: #4e4e4e;font-size:15px;height:32px;width:100%;">
اضغط على ارسال لتأكيد سومتك..
</div>
<div v-if="som_valid=='no'&&input_val>0" class="home-pop-som-form-box" style="
align-items:center;margin-top:18px;margin-right:auto;margin-left:auto;
color: #4e4e4e;font-size:15px;width:100%;">
<span class="mdi mdi-24px mdi-alert"
style="color:#c9515c;"></span>
<span style="padding-right:6px;">
اقل مبلغ لزيادة السوم {{som_plus}} ريال
</span>
</div>
<div v-if="send_done==false" class="home-pop-som-form-box" 
style="margin-right:auto;margin-left:auto;">
<div class="home-pop-som-form-dark-btn" v-on:click="view_post()" v-opacity="0.94"
style="margin-top:24px;margin-bottom:14px;min-width:288px;">
<span style="display: flex;flex-wrap:wrap;align-content: center; width:auto;height:55px;">
<span style="font-size: 18px;">ارسال</span>
</span>
</div>
</div>
</div>
<div class="home-pop-som-form animate__animated animate__fadeInLeft animate__faster" style="" v-if="send_done==true">
<div v-if="send_done==true" style="margin-top:14px;height: auto;display: flex; flex-wrap:wrap; align-content: center;justify-content: center !important;
width: 100%;touch-action: pan-y !important;">
<span style="width:92px;height:92px;border-radius:50%;background:rgba(82, 180, 74,0.1);display: flex; flex-wrap:wrap; align-content: center;justify-content: center; ">
<img src="img/done.svg" style="display:flex;justify-content:center;width:100%;height:100%;object-fit:contain;" />
</span>
</div>
<div class=""
v-if="send_done==true" style="font-size: 16px;padding: 14px 0px 14px 0px;
display: flex; flex-wrap:wrap; align-content: center;height: auto;
justify-content: center !important; width: 100%; touch-action: pan-y !important; ">
<span class="pop-som-rap" style="height:36px;color:#353c45;font-size: 21px;">تم ارسال طلبك بنجاح</span>
<span class="pop-som-rap"  style="color:#353c45;font-size: 16px;">سيتم التواصل معك من فريقنا</span>
<span class="pop-som-rap"  style="color:#353c45;font-size: 16px;">بعد قبول سومتك شكرا.. </span>
</div>
</div>
</div>
</div>
</template>
<script>
import { mapState} from "vuex";
import axios from 'axios';
export default {
name: 'ItemSomPop',
components: {},
//props: ['text'],
data(){
return {
ck_posts:[],
code_ck:false,
input_name:'',
input_phone:'',
input_val:'',
send_done:false,
som_start:0,
som_end:0,
som_plus:0,
somtk:0,
som_valid:'',
mobile_valid:'',
cksom:false,
send_data:[],
popup:''  
}
},
mounted(){
this.ck_som();
},
methods: {
//cksom.aspx
ck_som:function () {
axios.get(this.api_url+'ck_som.aspx?som='+this.dash_key+'&link='+this.pop_som)
.then((response) => {
if(response.data.status=='ok'){
this.som_start=response.data.som_start;
this.som_end=response.data.som_end;
this.som_plus=response.data.som_plus;
this.cksom=true;
}
else{
this.Swal_Error(response.data.status);
}
},(error) => {
this.Swal_Error(error);
});
},
view_post:function () {
var som_plus=parseInt(this.som_plus.toString());
var regex = new RegExp(/^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})|[\u0660-\u0669]{10}$/);
var ck_som_end=parseInt(this.input_val);
if (this.input_phone !=''&&this.input_val!=''){
if(regex.test(this.input_phone)){
if(ck_som_end>=som_plus){
var som_price=this.som_end+ck_som_end;
//var date = new Date();
//var second = date.toLocaleString("en-US", {timeZone: "Asia/Riyadh", second: "2-digit"});
//var minute= date.toLocaleString("en-US", {timeZone: "Asia/Riyadh", minute: "2-digit"});
//this.send_data.push({name:this.input_name,mobile:this.input_phone});
var send_data={
"auth":'kk660-s0669-b5698-z0669-1056',
"phone":this.input_phone,
"som_price":parseInt(som_price.toString())
};
axios.post(this.api_url+'send_som.aspx?som='+this.dash_key
+'&link='+this.pop_som
//+'&json='+second+'bc5q'+minute+'7zm'
,send_data,{
headers: {
'Content-Type': 'application/json'
}
})
.then((response) => {
if(response.data.status=='ok'){
this.send_done=true;
//this.Swal_Success('تم ارسال طلبك بنجاح');
}
else{
this.Swal_Error(response.data.status);
}
},(error) => {
this.Swal_Error(error);
});
}
else{
this.$swal.fire({
width: 325,
icon: 'error',
html:'<div style="display:flex;flex-wrap:wrap;height:auto;width:100%;'+
'justify-content:center;align-content:center;font-family:Noto Kufi Arabic;">'+
'<span style="font-size:21px;display:flex;flex-wrap:wrap;width:94%;justify-content:center;">المبلغ غير صحيح!!</span>'
+'<span style="font-size:18px;display:flex;flex-wrap:wrap;width:94%;justify-content:center;">'+'اضف سومة اعلى'+'</span>'
+'<br />'+
'<span style="font-size:16px;display:flex;flex-wrap:wrap;width:94%;justify-content:center;">من '+(this.som_end+this.som_plus)+' ريال'+'</span>'
+'</div>',
showConfirmButton: true,
//confirmButtonColor: '#4e4e4e',
confirmButtonText: 'رجوع',
});
}
}
else{
this.Swal_Error('رقم الجوال غير صحيح !!');
}
}
else{
this.Swal_Error('الرجاء اضافة المبلغ ورقم الجوال !!');
}
},
//Swal_Success
formatPrice : function (value){
var val = parseInt(value);
var ret = '';
if (typeof val !== "number") {
return value;
}
if(val>1000&&val<900999){
ret=' ألف';
const formatter = new Intl.NumberFormat('en-US', {
style: 'decimal', 
minimumFractionDigits: 0,
maximumFractionDigits: 6,
useGrouping: true
});
return formatter.format(val)+ret;
}
else if(val>1000000){
ret=' مليون';
const formatter = new Intl.NumberFormat('ar-sa', {
minimumFractionDigits: 0,
maximumFractionDigits: 6,
useGrouping: false
});
return formatter.format(val);
}
else{
return value;
}
},
Swal_Success:function (val){
this.$store.commit("Swal_Success", val);
},
Swal_Error:function (val){
this.$store.commit("Swal_Error", val);
},
toEngNum:function(val){
var res='';
const chars = val.toString().split('');
chars.filter(item=>{
switch (item){
case '٠': res+='0'; break;
case '١': res+='1'; break;
case '٢': res+='2'; break;
case '٣': res+='3'; break;
case '٤': res+='4'; break;
case '٥': res+='5'; break;
case '٦': res+='6'; break;
case '٧': res+='7'; break;
case '٨': res+='8'; break;
case '٩': res+='9'; break;
}
});
return res;
},
isNumberKey: function (event){
//  var regex = new RegExp(/^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/);
/*  if (regex.test(this.input_mobile)) {this.mobile_ck = true;}*/
var charCode = (event.which) ? event.which : event.keyCode
if (charCode > 31 && (charCode < 48 || charCode > 57)
 && (charCode < 1632 || charCode > 1641) )
{
this.input_phone = this.input_phone.toString().replaceAll(/[^0-9]/g, '');
this.input_val = this.input_val.toString().replaceAll(/[^0-9]/g, '');
//this.input_mobile = this.input_mobile.slice(0, -1);
return this.Swal_Error('الحروف غير مسموح بها !!');
}
return true;
},
btn_show:function (val){
$(val).hide();
$(val).show();
$(val).removeClass("animate__animated animate__fadeOutLeft animate__faster");
$(val).addClass("animate__animated animate__fadeInLeft animate__faster");
},
},
computed: {
...mapState({
api_url : state => state.api_url,
dash_key : state => state.dash_key,
pop_som : state => state.pop_som,
})
},
watch:{
input_phone(){
var v=this.input_phone;
var ck_mob = new RegExp(/^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})|[\u0660-\u0669]{10}$/);
if(ck_mob.test(v)){
this.mobile_valid='yes';
}
//mobile_valid
var regex = new RegExp(/[\u0660-\u0669]/g);
if(regex.test(v)){
this.input_phone=this.input_phone.replace(/[\u0660-\u0669]/g, (c) =>
String.fromCharCode(c.charCodeAt(0) - 0x0660 + 0x0030)
);
}
},
input_val(){
var v=this.input_val;
//const chars = this.input_val.toString().split('');
//var regex = new RegExp(/[٠-٩]/g);
var regex = new RegExp(/[\u0660-\u0669]/g);
if(regex.test(v)){
this.input_val=this.input_val.replace(/[\u0660-\u0669]/g, (c) =>
String.fromCharCode(c.charCodeAt(0) - 0x0660 + 0x0030)
);
}
var user_som=parseInt(v.toString());
var som_end=parseInt(this.som_end);
var som_plus=parseInt(this.som_plus);
if(user_som>=som_plus){
this.somtk=(som_end+user_som);
this.som_valid='yes';
}
else{
this.somtk=(som_end+user_som);
this.som_valid='no';
}
}
}
}
</script>
<style scoped>
.yes{background:#fff;}
.no{background:#fff4f4;color:#ce4141;}
.pop-som-rap{
position:relative;display:flex;flex-wrap:wrap;width:100%;
justify-content:center;align-content:center;
}
.pop-som-home-box{
background:none;z-index:0;position:relative;cursor:pointer;
display: flex; flex-wrap: wrap;height:auto;width:100%;
justify-content:center;align-content:center;padding-right:18px;padding-left:18px;
color:#4e4e4e;font-weight:500;white-space: pre-wrap;font-size:16px;text-align:center;
}
.home-pop-som-info{
position:relative; display: flex; flex-wrap:wrap;height:auto;
width: 100%;justify-content:center; align-content: center;
}
.home-pop-som-form{
position:relative;display: flex;flex-wrap:wrap;height:auto;
width: 100%;justify-content:center; align-content: center;
}
.pop-som-rap{
position:relative;display:flex;flex-wrap:wrap;
align-content: center;justify-content: center !important; 
}
.home-pop-som-form-box{
position:relative;display:flex;flex-wrap:wrap;width: 100%;
align-content: center;justify-content: center !important; 
}
input[type="text"] {
background-color: rgb(252, 252, 251);
min-height:64px;
border:1px solid #aaaaaa;
border-radius: 32px;
font-size:16px;
outline: none !important;
overflow: hidden !important;
text-align:center;
}
.home-pop-som-form-dark-btn{
display: flex;flex-wrap:wrap;
align-content: center;justify-content: center !important;
background:#353c45;color:#fff;
border-radius:32px;width:300px;height:72px;max-height:72px;
}

@media (min-width: 1024px){
.home-pop-som-info{
width:94%;align-content:flex-start;margin-bottom:7px;
}
.home-pop-som-form{
width: 94%;align-content:flex-start;margin-bottom:14px;
}
.home-pop-som-form-box{
width: auto;margin-left:14px;margin-right:auto;
}
.home-pop-som-form-box:last-child{
width: auto;margin-right:auto;
}
.home-pop-som-form-dark-btn{
width:224px;height:65px;border-radius:48px;
}
}
</style>
