<template>
<div class="home-tags-box">
<div class="home-tags-box-sw">
<swiper :options="swiperv2" dir="rtl"
style="position:relative;display:flex;align-content:center;margin-right:auto;margin-left:auto;
width:calc(100% - 65px);overflow:hidden;justify-content:center;">
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'قولد')">#قولد</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'سوزوكي')">#سوزوكي</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'دوكاتي')">#دوكاتي</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'هارلي')">#هارلي</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'موقف')">#موقف</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'هايبوزا')">#هايبوزا</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'امريكي')">#امريكي</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'كوبي')">#كوبي ون</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'كاسم')">#كاسم</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'حماية')">#حماية</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'مرتبة')">#مرتبة</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'USB')">#USB</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'بلوتوث')">#بلوتوث</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'مسكات')">#مسكات</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'هدروليك')">#هدروليك</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'بلاك')">#بلاك</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'اسود')">#اسود</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'احمر')">#احمر</a>
</swiper-slide>
<swiper-slide class="home-tags-slide">
<a v-on:click="$router.push('/tags/'+'اصفر')">#اصفر</a>
</swiper-slide>
</swiper>
<div class="swbt2-prv swiper-button-prev" slot="button-prev" 
 style="color:#ddd;position:absolute;top:30px;left:0px;"></div>
<div class="swbt2-nxt swiper-button-next" slot="button-next" 
 style="color:#ddd;position:absolute;top:30px;right:0px;"></div>
</div>
</div>
</template>
<script>
import { mapState } from 'vuex';
import { Swiper, SwiperSlide} from 'vue-awesome-swiper'
export default {
name: 'ViewTags2',
components: {
Swiper,SwiperSlide
},
//props: {msg: String},
data(){
return{
swiperv2:{
slidesPerView: 'auto',
autoHeight: true,
spaceBetween: 0,
pagination: {
el: '.swiper-pagination',
clickable: true
},
navigation: {
nextEl: ".swbt2-prv",
prevEl: ".swbt2-nxt"
}
},
}
},
methods:{

},
watch:{

},
computed: {
...mapState({
setup: state => state.setup,
//view_Nav:state => state.view_Nav,
})
}
}
</script>
<style scoped>
.home-tags-box{
/*background: linear-gradient(to bottom right, #fff 50%,#f1f1f1 20%,#fdfdfd 30%);*/
position:relative;display: flex;flex-wrap:wrap;
width:100%;height:75px;color:#4e4e4e;
position:relative;z-index:0;
/*padding:14px;*/
margin-right:auto;margin-left:auto;
font-family: 'Cairo', sans-serif;font-weight:400;
cursor:pointer;justify-content: right;align-content:center;
}
.home-tags-box-sw{
position:relative;display: flex;flex-wrap:wrap;
width:100%;height: auto;color:#4e4e4e;
position:relative;z-index:0;height:60px;
margin-right:auto;margin-left:auto;align-content:center;
font-family: 'Cairo', sans-serif;font-weight:400;
cursor:pointer;justify-content: right;
}
.home-tags-slide{
position:relative;
display:flex;flex-wrap:wrap;align-content:center;justify-content:center;
font-size:16px;color:#4e4e4e;height:52px;border-radius:8px;
margin-left:12px;margin-bottom:0px;width:85px;
border-top:1px solid #ccc;
border-right:1px solid #ccc;
border-bottom:1px solid #cdcdcd;
border-left:1px solid #ccc;
}
.home-tags-box a{
display:flex;flex-wrap:wrap;align-content:center;justify-content:center;
font-size:16px;color:#4e4e4e;width:100%;
}
.home-tags-box a:link{color:#4e4e4e;}
.home-tags-box a:visited{color:#4e4e4e;}

@media (min-width: 200px) and (max-width: 575px){
.home-tags-box{
justify-content:center;
}
}
@media (min-width: 800px) and (max-width: 1200px){

}
@media (min-width: 1200px){

}

</style>