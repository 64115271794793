<template>
<div class="listbox-home-imgkm" 
style="position:absolute;top:0px;right:0px;display: flex;flex-wrap:wrap;
height:60px;max-height:60px;width:100%;justify-content: center; align-content:center; z-index:1;">
<div class="bk_Like_fix_btn" v-on:click="set_pop_story('');" style="display: flex;flex-wrap:wrap;
border-radius: 4px;height:60px;max-height:60px;width:auto;color:rgba(255,255,255,.9);
font-size:12px;font-weight:400;white-space:nowrap;padding-right:7px;padding-top:7px;
justify-content: center;align-content:center;padding-left:7px;">
<div style="display: flex;flex-wrap:wrap;background: rgba(0,0,0,.2);
border-radius: 4px;height:42px;max-height:42px;width:auto;color:rgba(255,255,255,.9);
white-space:nowrap;padding-left:7px;padding-right:7px;
justify-content: center;align-content:center;">
<span class="mdi mdi-18px mdi-chevron-right" style="display: flex;flex-wrap:wrap;color:#f7f6f6;
opacity:0.8;line-height:42px;align-content:center;">
</span>
<span style="font-size:14px;line-height:40px;">
رجوع <span style="display:none;"></span>
</span>
</div>
</div>
<div class="set_Like_fix_btn" v-on:click="showInfo('.fix-count-info.like'+'-'+item.code),set_Like(item.short_link)" 
style="display: flex;flex-wrap:wrap;margin-right:auto;
border-radius: 4px;height:60px;max-height:60px;width:auto;color:rgba(255,255,255,.9);
font-size:12px;font-weight:400;white-space:nowrap;padding-top:7px;
justify-content: center;align-content:center;padding-left:7px;">
<div style="display: flex;flex-wrap:wrap;background: rgba(0,0,0,.2);
border-radius: 4px;height:42px;max-height:42px;width:42px;position:relative;
white-space:nowrap;justify-content: center;">
<span class="mdi mdi-24px mdi-heart-outline" style="color:#f7f6f6;line-height:42px;opacity:0.8;"></span>
<div :class="'animate__animated animate__headShake fix-count-info like'+'-'+item.code" 
style="display:none;position:absolute;z-index:1;top:0px;left:35px;">
<span style="display: flex;flex-wrap:wrap;justify-content: center;align-content:center;
border:1px solid #ddd;background:#fdfdfd;color:#333;font-size:14px;width:72px;height:42px;line-height:42px;">
<span style="padding-left:3px;">تم</span>
<span class="mdi mdi-18px mdi-check" style="color:#2aca7e;line-height:42px;"></span>
</span>
</div>
</div>
</div>
</div>
</template>
<script>
import {mapState} from 'vuex';
//import 'swiper/css/swiper.min.css';
export default {
name: 'ItemStoryTopBar',
components: {},
props: ['item'],
data(){
return {

}
},
mounted(){

},
methods:{
set_pop_story:function(val){ 
this.$store.commit("set_pop_story",val);
},
set_pop_like:function(val){ 
this.$store.commit("set_pop_like",val);
},
dis_like : function (guid) {
var i = 0;
this.likes.filter(item =>{
if(item.like==guid){
this.likes.splice(i,1);
}
i++;
});
this.lazy_update();
$('img.lazy').removeAttr('data-ll-status');
this.$cookies.set('likes',this.likes);
},
set_Like:function (guid){
var like_posts=null;
var ck =this.likes.filter(item =>{
return item.like==guid;   
});
if(ck.length<1){
this.posts.map(item =>{
if(item.guid==guid||item.short_link==guid){
var imgaes=item.imgaes.filter(img =>{return img.home==true;});
like_posts={"like":guid,"title":item.title,"imgaes":imgaes,"price":item.price};
}
});
this.$store.commit("push_likes",like_posts);
this.$cookies.set('likes',this.likes);
}
},
lazy_update(){
this.$store.commit("lazy_update");
},
show_all_info:function(code,timeout) {
setTimeout(function () {
$('.room-'+code).show();
}, 800);
setTimeout(function () {
$('.lounge-'+code).show();
}, 1200);
setTimeout(function () {
$('.bathroom-'+code).show();
}, 1600);
setTimeout(function () {
$('.room-'+code).fadeOut();
$('.lounge-'+code).fadeOut();
$('.bathroom-'+code).fadeOut();
}, timeout);
$('.story-box-view img.lazy').removeAttr('data-ll-status');
this.lazy_update();
},
showInfo:function(className) {
$(className).show();
setTimeout(function () {
$(className).fadeOut();
}, 800);
}
},
created() {
},
destroyed(){
},
updated() {
},
computed: {
...mapState({
//db : state => state.db,
hosturl : state => state.hosturl,
pop_story:state => state.pop_story,
posts:state => state.posts,
likes: state => state.likes
}),
filteredList(){
var like_posts=[];
this.likes.map(litem =>{
this.posts.map(pitem =>{
if(pitem.guid==litem.like){
like_posts.push(pitem);
}
})
});
return like_posts;   
}
},
watch:{

}
}
</script>
