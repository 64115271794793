<template>
<div class="j7-view-details" style="font-family: 'Noto Kufi Arabic', sans-serif;
width:100%;display: flex; flex-wrap: wrap;justify-content:center;align-content:center;
margin:auto;
padding-left:14px;padding-right:14px;">
<div class="j7-view-details-box" style="">
<div style="line-height:55px;
border-radius:4px;align-content:center;
display: flex; flex-wrap: wrap;width:100%;justify-content:center;position:relative;">
<div class="brk7"></div>
<div class="j7-view-details-item-name" style="display: flex; flex-wrap: wrap;height:55px;line-height:55px;
position:relative;width:100%;justify-content:right;align-content:center;">
<span style="padding-right:10px;color:#4e4e4e;font-size:18px;">التفاصيل الاضافية</span>
<span class="mdi mdi-24px mdi-chevron-down" style="color:#4e4e4e;margin-right:5px;padding-left:14px;"></span>
</div>
<div class="brk7"></div>
</div>
<div v-if="vitem.price" class="j7-view-details-item">
<div class="j7-view-details-item-name" 
style="color:#4e4e4e;padding-top:5px;padding-bottom:5px;border-bottom:1px solid #ddd;">
<span class="mdi mdi-18px mdi-checkbox-blank-circle-outline" style="color:#54b371;padding-left:5px;"></span>
<span>السعر </span>
</div>
<div class="j7-view-details-item-val" 
style="color:#4e4e4e;padding-top:10px;padding-bottom:5px;">
<a>{{vitem.price}}</a>
<a v-if="!vitem.price.toString().includes('سوم')" style="padding-right:5px;">ر.س</a>
</div>
</div>
<div v-if="vitem.area" class="j7-view-details-item">
<div class="j7-view-details-item-name" 
style="color:#4e4e4e;padding-top:5px;padding-bottom:5px;border-bottom:1px solid #ddd;">
<span class="mdi mdi-18px mdi-checkbox-blank-circle-outline" style="color:#54b371;padding-left:5px;"></span>
<span>الممشى </span>
</div>
<div class="j7-view-details-item-val" 
style="color:#4e4e4e;padding-top:10px;padding-bottom:5px;">
<a>{{vitem.area}}</a>
</div>
</div>
<div v-if="vitem.type" class="j7-view-details-item">
<div class="j7-view-details-item-name" 
style="color:#4e4e4e;padding-top:5px;padding-bottom:5px;border-bottom:1px solid #ddd;">
<span class="mdi mdi-18px mdi-checkbox-blank-circle-outline" style="color:#54b371;padding-left:5px;"></span>
<span>الحالة </span>
</div>
<div class="j7-view-details-item-val" 
style="color:#4e4e4e;padding-top:10px;padding-bottom:5px;">
<a>{{vitem.type}}</a>
</div>
</div>
<div v-if="vitem.model" class="j7-view-details-item">
<div class="j7-view-details-item-name" 
style="color:#4e4e4e;padding-top:5px;padding-bottom:5px;border-bottom:1px solid #ddd;">
<span class="mdi mdi-18px mdi-checkbox-blank-circle-outline" style="color:#54b371;padding-left:5px;"></span>
<span>الموديل </span>
</div>
<div class="j7-view-details-item-val" 
style="color:#4e4e4e;padding-top:10px;padding-bottom:5px;">
<a>{{vitem.model}}</a>
</div>
</div>
<div v-if="vitem.gir" class="j7-view-details-item">
<div class="j7-view-details-item-name" 
style="color:#4e4e4e;padding-top:5px;padding-bottom:5px;border-bottom:1px solid #ddd;">
<span class="mdi mdi-18px mdi-checkbox-blank-circle-outline" style="color:#54b371;padding-left:5px;"></span>
<span>نوع القير </span>
</div>
<div class="j7-view-details-item-val" 
style="color:#4e4e4e;padding-top:10px;padding-bottom:5px;">
<a>{{vitem.gir}}</a>
</div>
</div>
<div v-if="vitem.brand" class="j7-view-details-item">
<div class="j7-view-details-item-name" 
style="color:#4e4e4e;padding-top:5px;padding-bottom:5px;border-bottom:1px solid #ddd;">
<span class="mdi mdi-18px mdi-checkbox-blank-circle-outline" style="color:#54b371;padding-left:5px;"></span>
<span>الشركة </span>
</div>
<div class="j7-view-details-item-val" 
style="color:#4e4e4e;padding-top:10px;padding-bottom:5px;">
<a>{{vitem.brand}}</a>
</div>
</div>
<div class="j7-view-details-item" v-for="(item_dt, index) in details">
<div class="j7-view-details-item-name" 
style="color:#4e4e4e;padding-top:5px;padding-bottom:5px;border-bottom:1px solid #ddd;">
<span class="mdi mdi-18px mdi-checkbox-blank-circle-outline" style="color:#54b371;padding-left:5px;"></span>
<span>{{item_dt.name}}</span>
</div>
<div class="j7-view-details-item-val" 
style="color:#4e4e4e;padding-top:10px;padding-bottom:5px;">
<a v-if="item_dt.val">{{item_dt.val}}</a>
<a v-if="item_dt.alt" style="padding-right:5px;">{{item_dt.alt}}</a>
</div>
</div>
<div v-if="features.length>0" style="line-height:55px;
border-radius:4px;align-content:center;
display: flex; flex-wrap: wrap;width:100%;justify-content:center;position:relative;">
<div class="brk7"></div>
<div class="j7-view-details-item-name" style="display: flex; flex-wrap: wrap;height:55px;line-height:55px;
position:relative;width:100%;justify-content:right;align-content:center;">
<span style="padding-right:10px;color:#4e4e4e;font-size:18px;">المميزات</span>
<span class="mdi mdi-24px mdi-chevron-down" style="color:#4e4e4e;margin-right:5px;padding-left:14px;"></span>
</div>
</div>
<div class="brk7"></div>
<div v-if="features.length>0" class="j7-view-details-item" v-for="(ft_item, index) in features" style="">
<div class="j7-view-details-item-name" style="color:#4e4e4e;padding-bottom:5px;">
{{ft_item}}
</div>
<div class="j7-view-details-item-val" style="color:#4e4e4e;padding-bottom:7px;">
<img src="/img/done.svg" style="display:flex;justify-content:center;width:24px;height:24px;max-height:24px;object-fit:contain;" />
</div>
</div>
</div>
</div>
</template>
<script>
export default {
name: 'ItemDetails',
components: {},
props: ['vitem','details','features'],
data(){
return {


}
},
methods: {
},
watch:{

}
}
</script>
<style scoped>
.brk24{display:flex;flex-wrap:wrap;width:100%;min-height:24px;position:relative;}
.brk14{display:flex;flex-wrap:wrap;width:100%;min-height:14px;position:relative;}
.brk5{display:flex;flex-wrap:wrap;width:100%;min-height:5px;position:relative;}
.brk7{display:flex;flex-wrap:wrap;width:100%;min-height:7px;position:relative;}
.mouser{opacity:1;}
.mouser:hover{opacity:0.7;}
.tab-pc-hide{display:none;}
.mouser-bg{background-color:#fff;}
.mouser-bg:hover{background-color:#fcfcfc;}
.shdow{box-shadow: 6px 11px 41px -28px #ddd;
  -webkit-box-shadow: 6px 11px 41px -28px #ddd;

}
.j7-view-details-box{
display:flex;flex-wrap:wrap;width:100%;justify-content:right;align-content:center;
border-top: 1px solid #eee;margin:auto;
}
.j7-view-details-item{
display:flex;flex-wrap:wrap;justify-content:center;align-content:center;
border: 1px solid #e6e6e6;
padding:5px;width:48%;font-size: 15px;border-radius:8px;
margin-top:1%;margin-left:1%;margin-bottom:1%;margin-right:1%;
}
.j7-view-details-item-name{
display:flex;flex-wrap:wrap;width:100%;justify-content:center;align-content:center;
text-align:center;
}
.j7-view-details-item-val{
display:flex;flex-wrap:wrap;width:100%;justify-content:center;align-content:center;
text-align:center;
color:#888;
}
.j7-view-details-item:nth-child(4n+1) {
border-bottom: 1px solid #ddd;border-left: 1px solid #ddd;
}
.j7-view-details-item:nth-child(4n+2) {
border-bottom: 1px solid #ddd;border-left: 1px solid #ddd;
}
.j7-view-details-item:nth-child(4n+3) {
background: #f9f9f9;
}
.j7-view-details-item:nth-child(4n+4) {
/*background: #f9f9f9;*/
}
.j7-view-details-item:last-child{
/*background: #f9f9f9;*/
}
</style>
<style scoped>
@media (min-width: 800px) and (max-width: 1200px){
.j7-view-details-box{
border-top: none;
}
}
@media (min-width: 1200px){
.j7-view-details-box{
border-top: none;
}
.j7-view-details-item{
display:flex;flex-wrap:wrap;justify-content:center;align-content:center;
border: 1px solid #e6e6e6;
padding:5px;width:30%;font-size: 15px;border-radius:8px;
margin-top:1%;margin-left:1%;margin-bottom:1%;margin-right:1%;
}
}


</style>