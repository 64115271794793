import { render, staticRenderFns } from "./ItemSomStateus.vue?vue&type=template&id=33d46810&scoped=true&"
import script from "./ItemSomStateus.vue?vue&type=script&lang=js&"
export * from "./ItemSomStateus.vue?vue&type=script&lang=js&"
import style0 from "./ItemSomStateus.vue?vue&type=style&index=0&id=33d46810&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33d46810",
  null
  
)

export default component.exports