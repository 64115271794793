<template>
<div class="menu-item-box listbox-home-warp" style="
background:#fff;border-radius:14px;padding-top: 24px;padding-bottom: 24px;width:94%;
">
<div style="display:flex; flex-wrap:wrap;width:100%;align-content:center;justify-content:center;
padding-bottom:14px;border-bottom:1px solid #eee;">
<span class="mdi mdi-24px mdi-heart-outline" style="color:#4e4e4e;padding-left:5px;line-height:24px;"></span>
<span style="font-family: 'Noto Kufi Arabic';font-size:18px;line-height:24px;">الاعلانات المفضلة</span>
<span style="font-family: 'Noto Kufi Arabic';font-size:18px;line-height:24px;padding-right:5px;">( {{likes.length}} )</span>
</div>
<div v-if="likes.length==0" style="display:flex;flex-wrap:wrap;width:100%;padding-top:24px;align-content:center;justify-content:center;">
<span class="mdi mdi-24px mdi-comment-multiple-outline" style="color:#4e4e4e;padding-right:14px;line-height:24px;"></span>
<span style="font-family: 'Noto Kufi Arabic';font-size:16px;line-height:24px;padding-right:14px;">
لايوجد اعلانات مفضلة
</span>
<span v-on:click="set_pop_like('')" style="display:flex; flex-wrap:wrap;width:100%;font-family: 'Noto Kufi Arabic';font-size:18px;
line-height:24px;justify-content:center;margin-top:14px;
">
<span v-rippleffect="" style="display:flex; flex-wrap:wrap;line-height:55px;width:255px;
border-top:1px dashed #aaaaaa;border-bottom:1px dashed #aaaaaa;height:55px;align-content:center; justify-content:center;">رجوع</span>
</span>
</div>
<div dir="ltr" class="--scroll_items-- listbox-home-box animate__animated animate__fadeIn" 
style="max-height:500px;overflow-x:hidden;overflow-y:scroll;
scrollbar-color:#ccc #f4f4f4;
scrollbar-width:thin;min-width:320px;">
<div dir="rtl" v-opacity class="listbox-home-item animate__animated" :id="'animate-item'+(index+1)"
v-for="(item, index) in likes" style="padding-top:4px;height:auto;max-height:auto;" 
data-animation="animate__fadeInRightBig"
:data-row="index">
<!--<router-link to="/item" replace></router-link>-->
<!--hosturl+'/media/sm'+img.src-->
<div style="display:flex; flex-wrap:wrap;width:100%;align-content:center;justify-content:center;
padding-top:10px;padding-bottom:10px;border-bottom:1px solid #eee;">
<div class="listbox-home-imgbox" v-for="(img, index) in item.imgaes" v-if="img.home"
 style="position:relative;
">
<div class="listbox-home-imgkm" v-on:click="dis_like(item.like)" 
style="position:absolute;top:0px;right:0px;display: flex;flex-wrap:wrap;
height:38px;max-height:38px;width:72px;justify-content: center; align-content:center; z-index:1;">
<div style="display: flex;flex-wrap:wrap;background: rgba(0,0,0,.3);
border-radius: 4px;height:36px;max-height:36px;width:72px;color:rgba(255,255,255,.9);
font-size:12px;font-weight:400;white-space:nowrap;
justify-content: center;align-content:center;padding-left:7px;padding-right:7px;">
<span class="mdi mdi-12px mdi-heart-outline" style="display: flex;flex-wrap:wrap;color:#eee;line-height:36px;align-content:center;">
</span>
<span style="font-family: 'Noto Kufi Arabic';line-height:36px;padding-right:3px;font-size:15px;font-weight:400;">
الغاء 
</span>
</div>
</div>
<div class="listbox-home-imgcat" v-on:click="$router.push('/'+item.like),setitem_index(index)" 
style="background: rgba(0,0,0,.4);position:absolute;bottom:1px;right:0px; display: flex;flex-wrap:wrap;
width:100%;justify-content: center;align-content:center;z-index:1;border-radius:0px 0px 8px 8px;">
<div class="listbox-home-data" style="display: flex;flex-wrap:wrap;align-content:center;">
<div class="listbox-home-data-name" style="width:94%;height:32px;justify-content:center;
font-size:15px;font-weight:400;align-content:center;color:#f7f6f6;white-space:nowrap;">
<span>
{{item.title}}
</span>
</div>
<div dir="rtl" class="listbox-home-data-address" style="width:94%;justify-content:center;opacity:0.8;
align-items:center;font-weight:400;color:#f7f6f6;white-space:nowrap;">
<span style="font-size:15px;">{{item.price}}</span>
<span v-if="!item.price.includes(' ')" style="font-size:14px;padding-right:5px;">{{currency}}</span>
</div>
<!--<div class="listbox-home-data-address" style="width:94%;justify-content:center;opacity:0.8;
align-items:center;font-size:13px;font-weight:400;color:#f7f6f6;white-space:nowrap;">
<span class="mdi mdi-18px mdi-map-marker-multiple" style="color:#f7f6f6;line-height:18px;"></span>
<span style="padding-right:3px;">
{{item.address}} 
</span>
</div>-->
</div>
</div>
<div class="listbox-home-img" style="user-select:none;
position:relative;z-index:0;border-radius:8px;overflow:hidden;">
<img v-if="img.type.includes('image')" v-on:click.self="$router.push('/'+item.like),setitem_index(index)" 
 alt="يتم التحميل..." class="lazy" style="
position:relative;z-index:0;height:100%;width:100%;object-fit: cover;"
draggable="false" :data-src="img.home==true?hosturl+'/media/md'+img.src:''">
<img v-if="img.type.includes('video')" v-on:click.self="$router.push('/'+item.like),setitem_index(index)" 
 alt="يتم التحميل..." class="lazy" style="
position:relative;z-index:0;height:100%;width:100%;object-fit: cover;"
draggable="false" :data-src="hosturl+'/media/sm'+img.poster">
<!--:poster="hosturl+'/media/sm'+img.poster"-->
</div>
</div>
</div>
</div>
</div>
</div>
</template>
<script>
import {mapState} from 'vuex';
export default {
name: 'ItemLike',
components: {},
//props: {msg: String},
data(){
return {
aos:null,
item_like:[],
iframe:null,
width: 0,
scrollWidth: 0,
left: 0,
index: 0,
swaip:0,load:'show',
ref_item_box:''
}
},
methods:{
set_pop_like:function(val){ 
this.$store.commit("set_pop_like",val);
},
touchHoldHandler : function () {
alert('touchHoldHandler');
},
dis_like : function (guid) {
var i = 0;
this.likes.filter(item =>{
if(item.like==guid){
this.likes.splice(i,1);
}
i++;
});
this.lazy_update();
$('img.lazy').removeAttr('data-ll-status');
this.$cookies.set('likes',this.likes);
},
set_Like:function (guid){
var ck =this.likes.filter(item =>{
return item.like==guid;   
});
if(ck.length<1)
this.$store.commit("push_likes",guid);
this.$cookies.set('likes',this.likes);
},
formatPrice : function (value) {
var val = parseInt(value);
if (typeof val !== "number") {
return value;
}
if(val>1000&&val<900999){
const formatter = new Intl.NumberFormat('ar-sa', {
notation: 'compact',
minimumFractionDigits: 0,
maximumFractionDigits: 6
});
return formatter.format(val);
}
else if(val>1000000){
const formatter = new Intl.NumberFormat('ar-sa', {
notation: 'compact',
minimumFractionDigits: 6,
maximumFractionDigits: 6
});
return formatter.format(val);
}
else{
return value;
}
},
lazy_update(){
this.$store.commit("lazy_update");
},
SetViewCat: function (val) {
this.$store.commit("SetViewCat", val);
},
SetviewNav: function (val) {
this.$store.commit("SetviewNav", val);
},
SetPreview : function (val) {
this.$store.commit("SetPreview", val);
},
setitem_index(val){
this.$store.commit("setitem_index", val);
},
setpage:function (val) {
this.$store.commit("setpage", val);
//$('.nav-icon').addClass('mdi-tag-multiple');
//$('.item-home-nbtn').removeClass('active');
//$('.item-home-nbtn:nth-child(' + (val+1) + ')').addClass('active');
//$('.item-home-nbtn:nth-child(' + (val+1) + ')').find('span').removeClass('mdi-tag-multiple').addClass('mdi-checkbox-marked');
},
visibilityChanged:function(isVisible,entry,code) {
if(isVisible){
//console.log(entry);
var animation=$("#"+entry.target.id).data('animation');
if(!entry.target.classList.contains(animation)){
//let domElement = entry.target.id;
entry.target.classList.add(animation);
}
this.show_all_info(code,3200);
}
},
show_all_info:function(code,timeout) {
$('.room-'+code).show();
setTimeout(function () {
$('.lounge-'+code).show();
}, 600);
setTimeout(function () {
$('.bathroom-'+code).show();
}, 1200);
setTimeout(function () {
$('.room-'+code).fadeOut();
$('.lounge-'+code).fadeOut();
$('.bathroom-'+code).fadeOut();
}, timeout);
},
showInfo:function(className) {
$(className).show();
setTimeout(function () {
$(className).fadeOut();
}, 800);
}
},
created() {
this.lazy_update();
window.addEventListener('scroll', this.scrollFunction);
},
destroyed(){
window.removeEventListener('scroll', this.scrollFunction);
},
mounted(){
this.lazy_update();
},
updated() {
//var lazyLoadInstance = new LazyLoad({});
this.lazy_update();
},
computed: {
...mapState({
selctedCat : state => state.selctedCat,
view_Nav:state => state.view_Nav,
//db : state => state.db,
cats : state => state.cats,
items : state => state.items,
posts : state => state.posts,
viewCat : state => state.viewCat,
hosturl : state => state.hosturl,
currency : state => state.currency,
hide_navbar : state => state.hide_navbar,
preview_index : state => state.preview_index,
pageview:state => state.pageview,
preview: state => state.preview,
likes: state => state.likes,
height_navbar : state => state.height_navbar

}),
filteredList(){
//var like_posts=[];
//this.likes.map(litem =>{
//this.posts.map(pitem =>{
//if(pitem.guid==litem.like||pitem.short_link==litem.like){
//like_posts.push(pitem);
//}
//})
//});
//return like_posts;   
}
},
watch:{
// whenever question changes, this function will run
search_val: function (v)
{
//$('.item-home-box').find('.lazy').removeClass('entered ').removeClass('loaded').removeAttr('data-ll-status');
$('img.lazy').removeAttr('data-ll-status');
this.lazy_update();
},
pageview: function ()
{
//this.$refs.horizontal_item.scrollToIndex(this.pageview);
}
}
}
</script>
<style scoped>
.home-item-list-box{
display:flex;flex-wrap:wrap;justify-content:center;align-items:center;margin:0;
box-sizing: content-box;
overflow-x: hidden;
overflow-y: hidden;scrollbar-width:none;
}
.swip-item-home{
display: flex;width:100%;margin-bottom: 0px;
max-width:100%;padding:7px;
margin: 0;padding-bottom: 0px;border: none;
box-sizing: content-box;overflow-x: scroll;overflow-y: hidden;
scroll-snap-type: x mandatory;
scrollbar-width:none;
-webkit-overflow-scrolling: touch;
position:relative;
}
.listbox-home-warp{
display:flex; flex-wrap:wrap;height:auto;
width: 100%; position: relative;
touch-action: none;touch-action: pan-x pan-y;
position:relative;font-family: 'Noto Kufi Arabic';font-weight:400;
margin:0;justify-content: center;

}
.listbox-home-box{
display:flex; flex-wrap:wrap;width: 100%; 
/*padding-bottom:100px;*/
touch-action: pan-x pan-y; height: fit-content;
justify-content: center;
align-items: flex-start;
overflow-y: scroll; overflow-x: hidden; 
scrollbar-color: auto; scrollbar-width: none;
scroll-snap-type: mandatory;
}
.listbox-home-item{
position:relative;
display:flex;flex-wrap:wrap;
justify-content:center;text-align:center;align-items:center;
width:100%;
z-index: 0;cursor: pointer;
touch-action: none; touch-action: pan-x pan-y;
/*box-shadow: 0px 4px 4px 0px #f5f5f5;*/
overflow: hidden;
/*border-radius:5px 5px 5px 5px;*/
/*border-bottom:1px solid #ededed;*/

}
.listbox-home-imgbox{
display: flex; flex-wrap: wrap;
justify-content: center;align-items:center;
max-width:94%;width:100%;height:100%;
overflow: hidden;scrollbar-width: none;
}
.listbox-home-img{
display: flex; flex-wrap: wrap;
justify-content: center;align-items:center;
height:240px;width:100%;max-width:100%;
/*border-radius:5px 5px 5px 5px;*/
}
.listbox-home-data{
display: flex;
align-items: center;justify-content: center;
scrollbar-width: none;width:100%;height:72px;max-width:94%;
overflow: hidden;z-index: 0;
}
.listbox-home-data-box{
position:relative;width: 100%;max-height:128px;
display: flex;flex-wrap: wrap;
justify-content: center; justify-items:center;
text-align:center;z-index: 0;
}
</style>
<style scoped>
.listbox-home-data-name{
display: flex;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
justify-content: center;align-content:center;
}
.listbox-home-data-name span{
display:flex;justify-content:right;
max-width:75%;
overflow-x:scroll;overflow-y:hidden;
scrollbar-width:none!important;
}
.listbox-home-data-address{
display: flex;flex-wrap:wrap;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
justify-content: center;color:#000;
align-content:center;
}
.listbox-home-data-address span{
display:flex;flex-wrap:wrap;justify-content:right;
font-size:13px;
overflow-x:scroll;overflow-y:hidden;scrollbar-width:none;
}

.listbox-home-data-name img{
padding-right:5px;padding-left:5px;
}

.listbox-home-data-info{
display: flex;flex-wrap:wrap;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
color:#999;justify-content: center;align-content:center;
}
.listbox-home-data-info div{
display:flex;flex-wrap:wrap;justify-content:center;
font-size:12px;white-space:nowrap;align-content:center;
color:#999;scrollbar-width:none;
}
.listbox-home-data-info span{
display:flex;flex-wrap:wrap;justify-content:right;
font-size:12px;white-space:nowrap;padding-right:2px;padding-left:2px;
color:#999;align-content:center;
}
.listbox-home-data-info a{
display:flex;flex-wrap:wrap;
font-size:12px;white-space:nowrap;padding-right:2px;padding-left:2px;
color:#555;justify-content:right;align-content:center;
}
.listbox-home-data-price{
display: flex;flex-wrap:wrap;
/*box-shadow: 0px 2px 2px 0px #f5f5f5;*/
color: #000; justify-content: center;align-content:center;
}
.listbox-home-data-price span{
display:flex;flex-wrap:wrap;justify-content:right;
font-size:12px;white-space:nowrap;
color:#555;align-content:center;
margin:auto;
}
</style>
<style scoped>

@media (min-width: 200px) and (max-width: 575px){
.listbox-home-item{
width:94%;height:240px;max-width:94%;
overflow: hidden;
}
.listbox-home-data{max-width:100%;}
.listbox-home-img{height:240px;}
}

/*@media (min-width: 600px){
.listbox-home-item{
width:50%;height:320px;max-width:50%;
overflow: hidden;
}
.listbox-home-img{height:320px;}
.listbox-home-data{height:100px;max-width:100%;}
}*/

@media (min-width: 600px){
.listbox-home-item{
width:94%;height:240px;max-width:94%;
overflow: hidden;
}
.listbox-home-img{height:240px;}
.listbox-home-data{height:100px;max-width:100%;}
}


</style>