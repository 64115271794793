<template>
<div class="home-som-box" style="z-index:0;">
<div class="home-som-box-item" style="display:none;
width:94%;color:#2e2e2e;height:55px;z-index:10;">
<span class="mdi mdi-18px mdi-message-outline" 
style="color:#2e2e2e;padding-left:5px;padding-right:5px;"></span>
<span class="home-som-rap" style="width:auto;"> وصل السوم .. </span>
<span v-if="somList.length>0" class="home-som-rap" style="width:auto;">
<span v-if="somList">{{somList[0].som_end}}</span>
<span v-else>0</span>
<span style="padding-right:6px;">ريال</span>
</span>
</div>
<div class="home-som-box-item" style="width:100%;
padding-bottom:5px;"
v-on:click="set_pop_som(link)">
<div class="home-som-rap" v-opacity="0.8"
style="height:60px;font-size:18px;width:90%;margin-top:10px;
margin-bottom:18px;
border-bottom:1px solid #ddd;max-height:60px;overflow:hidden;
border-right:1px solid #ccc;z-index:10;
border-left:1px solid #ddd;background:#fff;
border-top:1px solid #ccc;cursor:pointer;	
border-radius:8px;align-items:center;
color:#2e2e2e;">
<span style="padding-left:14px;padding-right:14px;">اضف سومتك الان</span>
<span>⭐</span>
</div>
</div>

</div>
</template>
<script>
import { mapState} from "vuex";
export default {
name: 'ItemSomBtn',
components: {},
props: ['link'],
data(){
return {
}
},
methods:{
set_pop_som:function(val){ 
var vh=window.innerHeight;
//$('.item-bg').hide();
$('.item-bg').css('maxHeight',vh+'px');
this.$store.commit("set_pop_som",val);
}
},
computed: {
...mapState({
som : state => state.som,
}),
somList(){
return this.som.filter(item =>{
return item.link.toString()==this.link.toString()
});
}
},
watch:{

}
}
</script>
<style scoped>
.som-btn::before {
  content: "";
  position: absolute;
  width:100%;z-index:-10;
  background-image: linear-gradient(180deg,#00b7ff,#ff30ff);
  height:25px;
  animation: rotBGimg 4s linear infinite;
  transition: all .4s linear;
}

@keyframes rotBGimg{
0%{transform:rotate(0)}
to{transform:rotate(360deg)}
}
.home-som-box{font-family: 'Noto Kufi Arabic', sans-serif;
width:100%;display: flex; flex-wrap: wrap;justify-content:center;align-content:center;
margin:auto;}
.home-som-rap{
display: flex; flex-wrap: wrap;justify-content:center;align-content:center;
position:relative;
}
.home-som-box-item{
display: flex; flex-wrap: wrap;justify-content:center;align-content:center;
color:#2e2e2e;font-weight:500;white-space: pre-wrap;font-size:16px;
}

@media (min-width: 1024px){

}

</style>
