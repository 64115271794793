<template>
<div class="jvimg-list" style="display: flex;flex-wrap:wrap;width:98%;height:auto;justify-items:center;
font-size:15px;font-weight:500;align-items:center;color:#888;
cursor:pointer;margin:auto;justify-content:center;
font-family: Cairo, sans-serif;">
<div class="jvimg-box" v-for="(img, index) in imgaes" v-if="img.home" 
 style="user-select:none!important;position:relative;">
<!-- :style="img.home==true?'background:#000 url('+hosturl+'/media/md'+img.src+')':''" -->
<a v-if="img.type.includes('image')" data-fancybox="gallery" :data-src="hosturl+'/media/'+img.src" >
<img 
 alt="" class="lazy sk-light" style="border-radius:8px;user-select:none!important;
position:relative;z-index:0;height:100%;width:100%;object-fit: cover;"
draggable="false" :data-src="img.home==true?hosturl+'/media/md'+img.src:''"
:style="img.home==true?'background:#000 url('+hosturl+'/media/md'+img.src+')':''">
</a>
<!--<div class="skeleton loading" style="border-radius:8px;"></div>-->
<div v-if="vid.type&&vid.type.includes('video')" class="home_vide_player view-in-mobile sk-light" v-for="(vid, index) in imgaes" style="
backface-visibility: hidden;display:flex;flex-wrap:wrap;align-content:center;z-index:1;
justify-content:center;position:absolute;height:100%;width:100%;padding-right: 14px;
  padding-left: 14px;">
<video :src="hosturl+'/media/'+vid.src" preload="none"
id="player" 
 :poster="hosturl+'/media/md'+img.poster" class="plyr-vide"
 muted defaultMuted playsinline controls style="user-select:none;
height:100%;width:100%;object-fit: cover;text-align:center;">
<source :src="hosturl+'/media/'+vid.src" :type="vid.type" />
Your browser does not support the video tag.
</video>
 <!--v-on:click.self="play_vid('player')"--> 
</div>
<div v-if="vid.type&&vid.type.includes('video')"
 class="home_vide_player view-in-pc sk-light" 
v-for="(vid, index) in imgaes" style="
backface-visibility: hidden;display:flex;flex-wrap:wrap;align-content:center;z-index:1;
justify-content:center;position:absolute;height:100%;width:100%;padding-right: 14px;
  padding-left: 14px;">
<video :src="hosturl+'/media/'+vid.src" preload="none"
id="player" ref="player"
 :poster="hosturl+'/media/md'+img.poster" class="plyr-vide"
 muted defaultMuted playsinline controls style="
height:100%;width:100%;object-fit: cover;text-align:center;user-select:none;">
<source :src="hosturl+'/media/'+vid.src" :type="vid.type" />
Your browser does not support the video tag.
</video>
 <!--v-on:click.self="play_vid('player')"--> 
</div>
</div>
<div class="jvimg-link-box"
:style="imgaes.length>4 ? 'justify-content:center;':'justify-content:center;'">
<swiper :options="swiperOption" dir="rtl" ref="mySwiper" class="jvimg-link-slide-item"
style="display:flex;align-content:center;margin-right:auto;margin-left:auto;
width:100%;overflow:hidden;justify-content:center;">
<!--v-on:click="swipTo(index)"-->
<swiper-slide class="jvimg-link-item"
v-for="(img, index) in imgaes" :key="index" 
v-if="img.type.includes('image')">
<a :data-src="hosturl+'/media/'+img.src" data-fancybox="gallery"
 style="position:relative;">
<img 
 alt="" class="lazy sk-light" style="border-radius:8px;user-select:none!important;
position:relative;z-index:0;height:100%;width:100%;object-fit: cover;"
draggable="false" :data-src="hosturl+'/media/sm'+img.src">
</a>
</swiper-slide>
<div class="swiper-button-prev" slot="button-prev" style="color:#ddd;"></div>
<div class="swiper-button-next" slot="button-next" style="color:#ddd;"></div>
<!--<div class="swiper-pagination" slot="pagination" style="color:#ddd;"></div>-->
</swiper>
</div>
<span style="display:none;">{{loaded='loaded'}}</span>
<SoldOutV2 v-if="soldout==false" style="z-index:112;"></SoldOutV2>
</div>
</template>
<script>
import { Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css';
import Plyr from 'plyr';
import { Fancybox } from "@fancyapps/ui";
//import { Panzoom } from "@fancyapps/ui/dist/panzoom/panzoom.esm.js";
//import { Toolbar } from "@fancyapps/ui/dist/panzoom/panzoom.toolbar.esm.js";
//import "@fancyapps/ui/dist/panzoom/panzoom.toolbar.css";
//import "@fancyapps/ui/dist/panzoom/panzoom.css";
import {mapState} from 'vuex';
import SoldOutV1 from '../components/SoldOutV1.vue'
import SoldOutV2 from '../components/SoldOutV2.vue'
//import Plyr from 'plyr';

export default {
name: 'ItemImage',
components: {
Swiper,SwiperSlide,SoldOutV1,SoldOutV2
},
props: ['imgaes','soldout'],
data(){
return {
plyr:null,
loaded:'',
imgLoaded: 0,
imgIndex: null,
swiperOption:{
slidesPerView: 'auto',
autoHeight: true,
spaceBetween: 0,
pagination: {
el: '.swiper-pagination',
clickable: true
},
navigation: {
nextEl: ".swiper-button-next",
prevEl: ".swiper-button-prev"
}
}
}
},
methods: {
play_vid:function(val){ 
var player = document.getElementById('playvid-'+val);
//$('video').each(function() {
//if($(this).attr("id")!='playvid'+val){
//$(this).get(0).pause();
//}
//});
if (player.paused){
player.play();
//$('#playvid'+val).data('p-state','play');
$('#playvid-btn-on-'+val).hide();
$('#playvid-btn-off-'+val).hide();
}
else{
player.pause();
$('#playvid-btn-on-'+val).hide();
$('#playvid-btn-off-'+val).show();
}
},
play_vid_mute:function(val){ 
var player = document.getElementById('playvid-'+val);
if (player.muted){
player.muted = false;
$('#playvid-volume-on-'+val).show();
$('#playvid-volume-off-'+val).hide();
}
else{
player.muted = true;
$('#playvid-volume-on-'+val).hide();
$('#playvid-volume-off-'+val).show();
}
},
handleLoad:function(){
this.imgLoaded++;
$('.load-img'+this.imgLoaded).hide(); 
if(this.imgLoaded === this.imgaes.length) {
$('.skeleton').hide(); 
}
},
//onSwiper:function(swiper){
//console.log(swiper);
//},
//onSlideChange:function(){
//console.log('slide change');
//},
lazy_update(){
this.$store.commit("lazy_update");
},
swipTo(i){
this.swiper.slideTo(i, 1000, false)
}
},
created() {
this.lazy_update();
},
mounted() {
//const player = new Plyr('.plyr-vide');
//const player = new Plyr('.home_vide_player .plyr-vide');
this.plyr=new Plyr(this.$refs.player, {
controls: [
'play-large', // The large play button in the center
//'restart', // Restart playback
//'rewind', // Rewind by the seek time (default 10 seconds)
'play', // Play/pause playback
//'fast-forward', // Fast forward by the seek time (default 10 seconds)
'progress', // The progress bar and scrubber for playback and buffering
'current-time', // The current time of playback
'duration', // The full duration of the media
'mute', // Toggle mute
'volume', // Volume control
//'captions', // Toggle captions
'settings', // Settings menu
//'pip', 
//'airplay', // Airplay (currently Safari only)
//'download',
'fullscreen', // Toggle fullscreen
],
hideControls: false
});
this.lazy_update();
Fancybox.defaults.Hash = false;
Fancybox.bind('[data-fancybox="gallery"]', {
Images: {

},
Toolbar: {
display: {
left: [],
middle: [
"zoomIn",
"zoomOut",
"toggle1to1",
"flipX",
"flipY",
"rotateCW",
],
right: ["close"],
//"slideshow""thumbs""infobar", 
},
},
}); 
 
//Fancybox.bind('[data-fancybox="gallery"]', {});
//$(".gallery").fancybox({});
//$.fancybox.defaults.buttons=['thumbs','close'];
setTimeout(function (){
$('.sk-light').removeClass('sk-light');
}, 5000);
},
watch:{

},
computed: {
swiper(){
return this.$refs.mySwiper.$swiper
},
light_img(){
return this.imgaes.map(item =>
{
return this.hosturl+'/media/'+item.src
});
},
...mapState({
hosturl : state => state.hosturl
})
}
}
</script>
<style>
.plyr{
display:flex!important;flex-wrap:wrap!important;
justify-content:center!important;
width:100%!important;height:100%!important;
}
.plyr__video-wrapper{
display:flex!important;flex-wrap:wrap!important;
justify-content:center!important;
width:100%!important;height:100%!important;
}
.jvimg-link-box .swiper-container{
justify-content:center!important;height:92px;
justify-items:center!important;
}
.jvimg-link-box .swiper-container-rtl{
justify-content:center!important;height:92px;
justify-items:center!important;
}
.jvimg-link-box .swiper-wrapper{
justify-content:center!important;
justify-items:center!important;
}
.jvimg-link-box .swiper-button-prev{
right:1px!important;
}
.jvimg-link-box .swiper-button-next{
left:1px!important;
}
</style>
<style>
.fancybox__footer{display:none!important;}
html.with-fancybox{height:100vh!important;height: -webkit-fill-available!important;overflow:hidden; }
.fancybox__container{
height:100vh!important;height: -webkit-fill-available!important;

}
.fancybox__backdrop{
/*background: rgba(0, 0, 0, 0.5)!important;
backdrop-filter: blur(8px)!important;
-webkit-backdrop-filter: blur(8px)!important;*/
}
.fancybox__toolbar{position:relative;
display:flex;flex-wrap:wrap;justify-content:center;align-content:center;
width:100%;height:60px;margin:auto;}
.fancybox__toolbar__column.is-right{
position:fixed!important;top:0px;right:0px;background:none!important;
display:flex!important;flex-wrap:wrap!important;justify-content:right!important;align-content:center!important;
width:100%!important;height:72px!important;
}
.fancybox__toolbar__column.is-right .f-button{
width:75px!important;height:75px!important;background:none!important;}
.fancybox__toolbar__column.is-left{
position:fixed!important;top:0px;left:0px;background:none!important;
display:flex!important;flex-wrap:wrap!important;justify-content:left!important;align-content:center!important;
width:100%!important;height:72px!important;
}
.fancybox__toolbar__column.is-left .f-button{
width:75px!important;height:75px!important;background:none!important;}
.fancybox__toolbar__column .f-button{width:75px!important;height:75px!important;
margin:0!important;padding:0!important;}
.fancybox__toolbar__column.is-middle{
background:none!important;
position:fixed!important;bottom:1px;
display:flex!important;flex-wrap:wrap!important;
justify-content:center!important;align-content:center!important;
width:100%!important;height:138px!important;
}
.fancybox__footer{height:65px!important;}
.fancybox__toolbar{background-color:none!important;}
.fancybox__toolbar__column.is-middle .f-button{
width:33.33%!important;height:60px!important;
margin:0!important;padding:0!important;
background:none;
}

.fancybox__toolbar__column .f-button:hover{background:rgba(0, 0, 0, 0.22);}
.fancybox__toolbar__column .f-button:active{background:rgba(70, 70, 73, 0.44);}
.f-button svg{width:36px!important;height:36px!important;}
</style>
<style scoped>
.j7cnter{display:flex;flex-wrap:wrap;justify-content:center;align-content:center;
position:relative;width:100%;height:100%;
border:1px solid #ddd;
}
.jvimg-box{
user-select:none;display:flex;flex-wrap:wrap;justify-content:center;align-content:center;
position:relative;z-index:0;height:240px;width:100%;overflow:hidden;
padding-right:14px;padding-left:14px;
}
.jvimg-link-box{
user-select:none;padding:14px;
display:flex;flex-wrap:wrap;align-content:center;margin-right:auto;margin-left:auto;
position:relative;z-index:0;border-radius:8px;height:auto;width:100%;overflow:hidden;
}
.jvimg-link-item{
position:relative;height:72px;width:24%;border:1px solid #eee;border-radius:8px;
box-shadow: 0px 2px 2px 0px #f5f5f5;
}
.jvimg-link-item a{border:none;color:#fff;}
img{opacity:1;}
img:hover{opacity:0.8;}
</style>
<style>

@media (min-width: 200px) and (max-width: 575px){
.jvimg-link-box{width:100%!important;height:72px;}
.jvimg-link-box .swiper-container{
justify-content:center!important;height:72px;
justify-items:center!important;
}
.jvimg-link-box .swiper-container-rtl{
justify-content:center!important;height:72px;
justify-items:center!important;
}
.jvimg-link-item{width:24.33%!important;height:72px!important;}
.fancybox__toolbar__column.is-middle{background: rgba(0,0,0,.2)!important;height:auto!important;
justify-content:center!important;padding-bottom:14px;}
.fancybox__toolbar__column.is-middle .f-button{width:33.33%!important;height:72px!important;}
}

@media (min-width: 575px) and (max-width: 1080px){
.jvimg-box{width:100%!important;}
.jvimg-link-box{width:94%!important;}
.jvimg-link-item{width:24.33%!important;height:240px!important;}
.jvimg-link-box .swiper-container{
justify-content:center!important;height:240px;
justify-items:center!important;
}
.jvimg-link-box .swiper-container-rtl{
justify-content:center!important;height:240px;
justify-items:center!important;
}
.fancybox__footer{bottom:24px;}
.fancybox__toolbar__column.is-middle{
height:auto!important;justify-content:center!important;padding-bottom:18px;
bottom:0px;top:0px;right:72px;align-content:center!important;width:72px!important;
height:auto!important;
}
.fancybox__toolbar__column.is-middle .f-button{
width:72px!important;height:72px!important;
}
}
@media (min-width: 1080px) and (max-width: 1200px){
.jvimg-box{width:100%!important;}
.jvimg-link-box{width:100%!important;}
.jvimg-link-item{width:24.33%!important;height:240px!important;}
.jvimg-link-box .swiper-container{
justify-content:center!important;height:240px;
justify-items:center!important;
}
.jvimg-link-box .swiper-container-rtl{
justify-content:center!important;height:240px;
justify-items:center!important;
}
.fancybox__toolbar__column.is-middle{
bottom:0px;top:0px;align-content:flex-start!important;height:62px!important;
background: rgba(24,24,27,.2)!important;
justify-content:center!important;padding-bottom:14px;}
.fancybox__toolbar__column.is-middle .f-button{width:100px!important;height:62px!important;}
.fancybox__footer{bottom:10px;}
}
@media (min-width: 1200px) and (max-width: 1400px){
.jvimg-box{width:100%!important;height:360px!important;}
.jvimg-link-box{width:100%!important;}
.jvimg-link-item{width:24.33%!important;height:128px!important;}
.jvimg-link-box .swiper-container{
justify-content:center!important;height:128px;
justify-items:center!important;
}
.jvimg-link-box .swiper-container-rtl{
justify-content:center!important;height:128px;
justify-items:center!important;
}
.fancybox__toolbar__column.is-middle{
bottom:0px;top:0px;align-content:flex-start!important;height:62px!important;
background: rgba(24,24,27,.2)!important;
justify-content:center!important;padding-bottom:14px;}
.fancybox__toolbar__column.is-middle .f-button{width:100px!important;height:62px!important;}
.fancybox__footer{bottom:10px;}
}
@media (min-width: 1400px){
.jvimg-box{width:100%!important;height:360px!important;}
.jvimg-link-box{width:100%!important;margin-top:14px;}
.jvimg-link-item{width:20%!important;height:100px!important;}
.jvimg-link-box .swiper-container{
justify-content:center!important;height:100px;
justify-items:center!important;
}
.jvimg-link-box .swiper-container-rtl{
justify-content:center!important;height:100px;
justify-items:center!important;
}
.fancybox__toolbar__column.is-middle{
bottom:0px;top:0px;align-content:flex-start!important;height:62px!important;
background: rgba(24,24,27,.2)!important;
justify-content:center!important;padding-bottom:14px;}
.fancybox__toolbar__column.is-middle .f-button{width:100px!important;height:62px!important;}
.fancybox__footer{bottom:10px;}
}
@media (min-width: 200px) and (max-width: 992px){
.home_vide_player.view-in-mobile{display:flex!important;}
.home_vide_player.view-in-pc{display:none!important;}
}
@media (min-width: 992px){
.jvimg-link-box .swiper-wrapper{
justify-content:center!important;
justify-items:center!important;
}
.home_vide_player.view-in-mobile{display:none!important;}
.home_vide_player.view-in-pc{display:flex!important;}
}
</style>