<template>
<div class="PageHome j7app-main-com" dir="rtl"
 style="position:relative;">
<div class="home-nav j7app-header-home-pc view-in-pc" 
style="box-shadow:0px 1px 1px 0px #f7f6f6;max-width:100%;">
<TopBarPC style="background:rgba(255,255,255,0.85);border-bottom:1px solid #eee;"></TopBarPC>
<NavbarPc style="background:rgba(255,255,255,0.85);"></NavbarPc>
<!--<HeroSearch v-if="pop_hero=='hide'" style="border-bottom:1px solid #f7f6f6;"></HeroSearch>-->
</div>
<div class="home-nav j7app-header-home view-in-ipad" 
style="box-shadow: 0px 1px 1px 0px #f7f6f6;max-width:1366px;">
<TopNavbar style="background:rgba(255,255,255,0.95);"></TopNavbar>
<Navbar v-if="pop_hero=='hide'" style="background:rgba(255,255,255,0.95);border-bottom:1px solid #f7f6f6;"></Navbar>
</div>
<div class="main-home-page j7app-main-home" style="
max-width:100%;z-index:0;"
 :style="view_Nav=='menu'&&header_type=='fixed'?'padding-top:148px;':''">
<div class="list-item-nav" style="z-index:11;position:relative;display:flex;flex-wrap:wrap;width:100%;margin:auto;">
<ListItemNav style="z-index:11;position:relative;"></ListItemNav>
</div>
<!--<div class="list-item-left" style="display:flex;flex-wrap:wrap;width:calc(100% - 340px);">
</div>-->

<ItemListV2pc v-if="pop_hero=='hide'" style="
width:100%;margin:auto;padding:7px;"></ItemListV2pc>
</div>
<Footer v-if="prog==100&&pop_hero=='hide'" style="max-width:100%;background:rgba(255,255,255,0.8);
margin:auto;"></Footer>
<BottomNav v-if="pop_hero=='hide'"></BottomNav>
<div v-if="pop_hero=='hide'" class="LoadBar" style="background:#fff;position:fixed;width:100%;
height: 100vh; height: -webkit-fill-available;
touch-action: pan-x pan-y;
z-index:10;top:0;left:0;display:flex;flex-wrap:wrap;">
<LoadBar @load_state="emit_load_state($event)" :plus="5" :time="10"></LoadBar>
</div>
<div v-if="pop_hero==''" class="pop-up-intro" v-on:click.self="set_pop_hero('hide')">
<HomeHero style="touch-action: pan-x pan-y!important;z-index:999;"></HomeHero>
</div>
<div v-if="pop_story=='story'" class="pop-up-story" v-on:click.self="set_pop_story('')">
<ItemStorySwipe v-if="pop_story=='story'" style="touch-action: pan-x pan-y!important;z-index:999;"></ItemStorySwipe>
</div>
<div v-if="pop_like=='like'" class="pop-up-like" v-on:click.self="set_pop_like('')">
<ItemLike style="max-width:375px;touch-action: pan-x pan-y!important;"></ItemLike>
</div>
<div v-if="pop_order_by=='show'" class="pop-up-like" v-on:click.self="set_pop_order_by('')">
<ItemOrderBy style="max-width:575px;touch-action: pan-x pan-y!important;"></ItemOrderBy>
</div>
<div v-if="pop_som!=''" class="pop-up-like" v-on:click.self="set_pop_som('')">
<ItemSomPop :link="pop_som" style="max-width:575px;touch-action:pan-x pan-y!important;"></ItemSomPop>
</div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import HomeHero from '../components/HomeHero.vue'
import HeroSearch from '../components/HeroSearch.vue'
import TopNavbar from '../components/TopNavbar.vue'
import Navbar from '../components/navbar.vue'
import NavbarPc from '../components/NavbarPc.vue'
import ItemList from '../components/ItemList.vue'
import ItemListV2 from '../components/ItemListV2.vue'
import ItemListV2pc from '../components/ItemListV2pc.vue'
import BottomNav from '../components/BottomNav.vue'
import Footer from '../components/Footer.vue'
import LoadBar from '../components/LoadBar.vue'
import ItemLike from '../components/ItemLike.vue'
import ItemStory from '../components/ItemStory.vue'
import ItemStorySwipe from '../components/ItemStorySwipe.vue'
import TopBarPC from '../components/TopBarPC.vue'
import TopBarNavPC from '../components/TopBarNavPC.vue'
import ListItemNav from '../components/ListItemNav.vue'
import ItemOrderBy from '../components/ItemOrderBy.vue'
import SideBarPc from '../components/SideBarPc.vue'
import ItemSomPop from '../components/ItemSomPop.vue'

export default {
name: 'Homepage',
components: {
HomeHero,
TopNavbar,Navbar,ItemList,ItemLike,ItemStory,ItemStorySwipe,
BottomNav,Footer,ItemListV2,ItemListV2pc,LoadBar,NavbarPc,HeroSearch,
TopBarPC,TopBarNavPC,ListItemNav,ItemOrderBy,SideBarPc,ItemSomPop
},
data() {
return {
code_view:'',
header_type:'',
prog:0
}
},
methods: {
set_pop_like:function(val){ 
this.$store.commit("set_pop_like",val);
},
set_pop_order_by:function(val){ 
this.$store.commit("set_pop_order_by",val);
},
set_pop_filter_by:function(val){ 
this.$store.commit("set_pop_filter_by",val);
},
set_pop_hero:function(val){ 
this.$store.commit("set_pop_hero",val);
},
close_pup:function(val){ 
this.$store.commit("set_close_pup",val);
},
set_pop_story:function(val){ 
this.$store.commit("set_pop_story",val);
},
set_pop_som:function(val){ 
this.$store.commit("set_pop_som",val);
},
emit_load_state:function(event){ 
this.prog = event;
},
ck_header:function(){ 
if(window.window.innerWidth>1000){
this.header_type='relative';
}
else {
this.header_type=$('.j7app-header-home').css("position");
}
}
},
destroyed(){
window.removeEventListener('resize',  () => {});
},
mounted(){
if(this.posts.length<=0) {
this.$store.commit("GetApiJson","A6989477-31E7-4757-8D37-E64F131KKKKD");
}
this.$store.commit("set_page_title",this.cust_name);
this.ck_header();
//this.header_type=$('.j7app-header-home-pc').css("position");
window.addEventListener('resize',() =>{
//this.header_type=$('.j7app-header-home-pc').css("position");
this.ck_header();
});
},
updated() {
//alert('updated');
},
watch:{
prog(){
if(this.prog==100) {
$('.LoadBar').hide();
}
}
},
computed: {
...mapState({
pageview : state => state.pageview,
posts : state => state.posts,
view_Nav : state => state.view_Nav,
pop_like : state => state.pop_like,
pop_hero : state => state.pop_hero,
pop_story : state => state.pop_story,
pop_order_by : state => state.pop_order_by,
pop_filter_by : state => state.pop_filter_by,
pop_som : state => state.pop_som,
cust_name : state => state.cust_name
})
}
}
</script>
<style>
/*
background:linear-gradient(45deg, #f5f5f5,#e6e6e6);
background:#f7f6f6;
*/
.j7app-main-com{
position:relative;z-index:0;
width: 100%;display: flex;align-content:flex-start;
flex-wrap: wrap;margin-right:auto;margin-left:auto;
}
.pghome-con{
position:relative;z-index:0;
width: 100%;display: flex;align-content:flex-start;
flex-wrap: wrap;margin-right:auto;
}
.j7app-header-home{
z-index:1;
display: flex; flex-wrap: wrap;
width: 100%;margin-right:auto;margin-left:auto;max-height:158px;
align-items: flex-start;touch-action: pan-x;
border-radius:0; border: 0px solid transparent;
background:#f7f6f6;
padding:0;
}
.j7app-header-home-pc{
z-index:1;
display: flex; flex-wrap: wrap;
width: 100%;margin-right:auto;margin-left:auto;
align-items: flex-start;touch-action: pan-x;
border-radius:0; border: 0px solid transparent;
padding:0;
}
.j7app-main-home{
position:relative;z-index:0;
width: 100%;display: flex;align-content:flex-start;
flex-wrap: wrap;margin-right:auto;margin-left:auto;
padding-top:60px;
}

</style>
<style scoped>
.pop-up-intro{z-index:10;position:fixed;top:0;right:0;
background: rgba(0, 0, 0, 0.5);touch-action: pan-x pan-y!important;
backdrop-filter: blur(8px);
-webkit-backdrop-filter: blur(8px);
display:flex;flex-wrap:wrap;width:100%;min-height: 100vh;max-height:100vh;overflow:hidden;
min-height: -webkit-fill-available; user-select: none;margin-right:auto;margin-left:auto;
align-items: flex-start;justify-content:center;
cursor:pointer;
}
.pop-up-story{z-index:10;position:fixed;top:0;right:0;
background: rgba(0, 0, 0, 0.6);touch-action: pan-x pan-y!important;
backdrop-filter: blur(8px);
-webkit-backdrop-filter: blur(8px);
display:flex;flex-wrap:wrap;width:100%;min-height: 100vh;max-height:100vh;overflow:hidden;
min-height: -webkit-fill-available;user-select: none;margin-right:auto;margin-left:auto;
align-items: flex-start;justify-content:center;
cursor:pointer;
}
.pop-up-like{
z-index:10;position:fixed;top:0;right:0;
background: rgba(0, 0, 0, 0.5);
backdrop-filter: blur(8px);
-webkit-backdrop-filter: blur(8px);height:100%;
display:flex;flex-wrap:wrap;width:100%;max-height:100vh;overflow:hidden;
max-height: -webkit-fill-available; user-select: none;margin-right:auto;margin-left:auto;
align-content:center;justify-content:center;
cursor:pointer;
}
@media (min-width: 200px) and (max-width: 992px){
.j7app-header-home.view-in-ipad{position:fixed;}
.j7app-header-home-pc.view-in-pc{display:none!important;}
}
@media (min-width: 992px){
.j7app-main-home{padding-top:0px!important;}
.j7app-header-home-pc.view-in-pc{position:relative;}
.j7app-header-home.view-in-ipad{display:none!important;}
}
</style>