<template>
<div class="home-som-box">
<div class="home-som-box-item" style="width:100%;"
v-on:click="set_pop_som(link)" >
<div class="home-som-rap" 
style="height:55px;font-size:16px;width:94%;
background:rgba(0,0,0,.35);border-radius:4px;
color:#f9f9f9;"
>
<span style="padding-left:14px;padding-right:14px;">على السوم .. اضف سومتك الان</span>
<span>⭐</span>
</div>
</div>
</div>
</template>
<script>
export default {
name: 'ItemSomV1',
components: {},
props: ['link'],
data(){
return {
}
},
methods: {
set_pop_som:function(val){ 
this.$store.commit("set_pop_som",val);
}
},
watch:{

}
}
</script>
<style scoped>
.home-som-box{font-family: 'Noto Kufi Arabic', sans-serif;
width:100%;display: flex; flex-wrap: wrap;justify-content:center;align-content:center;
margin:auto;}
.home-som-rap{
display: flex; flex-wrap: wrap;justify-content:center;align-content:center;
position:relative;
}
.home-som-box-item{
display: flex; flex-wrap: wrap;justify-content:center;align-content:center;
color:#2e2e2e;font-weight:500;white-space: pre-wrap;font-size:16px;
}

@media (min-width: 1024px){

}

</style>
