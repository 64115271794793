<template>
<div class="animate__animated animate__bounceInRight j7app-appbar">
<div v-on:click="$router.push('/')" class="j7app-spacer b-btn-back" style="line-height:42px!important;cursor:pointer;margin-right:14px;height:48px;max-height:48px;overflow:hidden;">
<span class="mdi mdi-24px mdi-chevron-right" style="color:#1e3d60;line-height:48px!important;"></span>
<span style="font-size:16px;font-family: 'Noto Kufi Arabic', sans-serif;color:#4e4e4e;line-height:42px!important;">رجوع</span>
</div>
<div v-on:click="$router.push('/')" class="j7app-spacer b-logo" style=" margin-right:auto;margin-left:auto;cursor:pointer;
height:48px;max-height:32px;min-width:150px;max-width:150px;overflow:hidden;justify-content:center;align-items:center;">
<span style="font-family: 'Noto Kufi Arabic', sans-serif;font-size:16px;
color:#4e4e4e;line-height:48px;">
<img src="img/darajaaa-logo-128.png" style="line-height:48px;display:flex;justify-content:center;height:32px;object-fit:contain;" />
</span>
</div>
<div class="j7app-spacer b-help" style="margin-left:14px;position:relative;" v-on:click="showInfo('.fix-like'+'-'+guid),set_Like(guid)">
<span class="j7app-vbtn-icon">
<span class="mdi mdi-24px mdi-heart-outline j7app-span-icon" style="color:#888;"></span>
</span>
<div :class="'animate__animated animate__headShake fix-like'+'-'+guid"
 style="display:none;position:absolute;z-index:1;top:5px;left:40px;width:50px;">
<span style="display: flex;flex-wrap:wrap;justify-content:center;align-content:center;align-items:center;
border:1px solid #ddd;background:#fdfdfd;color:#333;font-size:14px;width:50px;height:28px;">
<span style="padding-left:3px;">تم</span>
<span class="mdi mdi-18px mdi-check" style="color:#2aca7e;"></span>
</span>
</div>
</div>
</div>
</template>
<script>
import {mapState} from 'vuex';
export default {
name: 'TopItembar',
components: {},
//props: {msg: String},
data(){
return {
guid:this.$route.params.guid
}
},
methods: {
showInfo:function(className) {
$(className).show();
setTimeout(function () {
$(className).fadeOut();
}, 800);
},
set_Like:function (guid){
var like_posts=null;
var ck =this.likes.filter(item =>{
return item.like==guid;   
});
if(ck.length<1){
this.posts.map(item =>{
if(item.guid==guid||item.short_link==guid){
var imgaes=item.imgaes.filter(img =>{return img.home==true;});
like_posts={"like":guid,"title":item.title,"imgaes":imgaes,"price":item.price};
}
});
this.$store.commit("push_likes",like_posts);
this.$cookies.set('likes',this.likes);
}
}
},
watch:{

},
computed: {
...mapState({
posts : state => state.posts,
likes: state => state.likes,
}),
},
}
</script>

<style scoped>
.j7app-spacer{
position: relative;z-index: 0;
display: flex;
}
.j7app-appbar{
position: relative; z-index: 0;
display: flex;width:100%;height:55px;
align-items: center;max-width:1366px;
margin-right:auto;margin-left:auto;
background:#fff;overflow:hidden;
}
.j7app-vbtn{
position: relative;background-color: transparent;
display: flex;
flex-basis: auto;border-radius: 0;
font-size: 14px;height: inherit;
max-width: 168px;min-width: 80px;
color: rgba(0,0,0,.54);cursor: pointer;
text-transform: none;box-shadow: none;
white-space: nowrap;user-select: none;
align-items: center;justify-content: center;
text-decoration: none;outline: 0;
-webkit-appearance: button;border-radius: 50%;
transition-duration: .28s;
transition-property: box-shadow,transform,opacity;
transition-timing-function: cubic-bezier(.4,0,.2,1);
}
.j7app-vbtn-icon{
position: relative;background-color:#f7f6f6;
display: inline-flex;flex: 0 1 auto;flex-grow: 0;flex-shrink: 1;
flex-basis: auto;border-radius: 0;
font-size: 14px;height: inherit;
height: 38px;width:38px;
max-height: 38px;max-width:38px;overflow:hidden;
cursor: pointer;
text-transform: none;box-shadow: none;
white-space: nowrap;user-select: none;
align-items: center;justify-content: center;
text-decoration: none;outline: 0;
-webkit-appearance: button;border-radius: 50%;
transition-duration: .28s;
transition-property: box-shadow,transform,opacity;
transition-timing-function: cubic-bezier(.4,0,.2,1);
}
.j7app-span-icon{
display:flex;width:100%;
height:24px;line-height:24px;
justify-content:center;align-content:center;
}
.j7app-span-icon.active{color:#e03d53;}
.j7app-vbtn-icon:hover{background-color:#f5f5f5;border:1px solid #eee;}
</style>
<style scoped>
.absolute-serach-warp{
position:absolute;top:0;right:0;
width:calc(100%);height:55px;z-index:100;
}
.absolute-serach-box{
position:relative; background:#fff;
display:flex;flex-wrap:wrap;margin-right:auto;
height:55px;width:100%;padding:0 14px 0 14px;
justify-content:center;align-content:center;
}
.absolute-serach-box-input{
display:flex;height:36px;width:calc(100% - 100px);
margin-right:14px;margin-left:14px;padding:0 14px 0 14px;
border-radius:10px;border:1px solid #ddd;
}
@media (min-width: 600px){
.absolute-serach-warp{width:calc(100% - 270px);right:125px;}
}
</style>
