<template>
<div class="animate__animated animate__bounceInRight top-bar-pc"
style="">
<!-- v-on:click="view_Nav=='admenu' ? view_Nav='menu' : view_Nav='admenu'" -->
<div class="top-bar-pc-contain" style="display:flex;flex-wrap:wrap;
justify-content:center;align-content:center;height:100px;
position:relative;width:100%;margin:auto;max-width:1400px;
">
<div class="topbar-right" v-on:click="$router.push('/')"
 style="display:flex;flex-wrap:wrap;justify-content:center;align-content:center;
cursor:pointer;overflow:hidden;position:relative;width:auto;">
<span style="font-size:18px;display:flex;flex-wrap:wrap;height:auto;
width:100%;justify-content:center;align-content:center;font-weight:400;
align-items:center;font-family: 'Noto Kufi Arabic', sans-serif;
color:rgba(72, 72, 72,.9);padding:0!important;">
<img src="/img/darajaaa-logo-128.png" style="display:flex;flex-wrap:wrap;
justify-content:center;
max-height:60px;position:relative;padding-left:5px;align-content:center;" />
 <!--الدراجة العنيفة-->
</span>
</div>
<div class="topbar-left" style="display:flex;flex-wrap:wrap;
justify-content:center;align-content:center;
position:relative;width:auto">
<div class="topbar-serach-box"
style="display:flex;flex-wrap:wrap;justify-content:center;align-content:center;
position:relative;width:500px;padding-left:24px;padding-right:24px;
margin-right:24px;margin-left:24px;">
<span v-on:click="Set_search_val"
 style="display:flex;flex-wrap:wrap;margin-left:32px;left:0;top:7px;
justify-content:center;align-content:center;cursor:pointer;
position:absolute;height:45px;width:45px;background:none;box-shadow:none;">
<span class="mdi mdi-24px mdi-magnify j7app-span-icon" style="color:rgba(72, 72, 72,.9);"></span>
</span>
<input class="absolute-serach-box-input" v-model="input_val"
type="text" lang="en" maxlength="255" v-on:keypress="toEnglishNumber" 
@paste="toEnglishNumber" v-on:keyup.enter="Set_search_val"
 style="height:55px;border-radius:4px;width:100%;padding-right:32px;
border-color:rgba(72, 72, 72,.2);color:rgba(72, 72, 72,.9);" 
placeholder="قولد ، كاواساكي ، سوزوكي ، للبيع ، 2015 ">
</div>
<div style="display:flex;flex-wrap:wrap;margin-right:auto;
justify-content:center;align-content:center;
position:relative;width:auto;">
<div v-on:click="set_pop_like('like')" style="display:flex;flex-wrap:wrap;
justify-content:center;align-content:center;cursor:pointer;
padding-right:14px;padding-left:14px;margin-left:18px;
position:relative;height:60px;background:#fff;border-radius:8px;">
<span style="position:absolute;top:-5px;right:-5px;display:none;">
<span class="favframes favload" style="font-family: 'Noto Kufi Arabic', sans-serif;
display:flex;flex-wrap:wrap;width:24px;height:24px;justify-content:center;border-radius:50%;
align-content:center;
font-size:10px;background-color:#ed8384;color:#fff;">{{likes.length}}</span>
</span>
<span dir="ltr" style="display:flex;flex-wrap:wrap;
font-family:'Noto Kufi Arabic',sans-serif;color:rgba(78,78,78,.8);
font-size:18px;font-weight:bold;padding-right:4px;
justify-content:center;align-content:center;">
المفضلة
</span>
<span class="mdi mdi-36px mdi-heart-outline" style="
display:flex;flex-wrap:wrap;padding-right:4px;
justify-content:center;align-content:center;color:#f09d9d;"></span>
</div>
<div style="display:flex;flex-wrap:wrap;
justify-content:center;align-content:center;
padding-right:14px;padding-left:14px;cursor:pointer;
position:relative;height:60px;background:#fff;border-radius:8px;">
<a v-if="setup[0]" dir="ltr" style="display:flex;flex-wrap:wrap;
font-family:'Noto Kufi Arabic',sans-serif;color:rgba(78,78,78,.8);
font-size:18px;font-weight:bold;padding-right:4px;
justify-content:center;align-content:center;"
:href="'https://api.whatsapp.com/send/?phone=966'+
send_number(setup[0].whatsap_no)+'&text&type=phone_number&app_absent=0'"
 target="_blank" title="تواصل معنا واتساب">
مساعدة
</a>
<span class="mdi mdi-36px mdi-whatsapp" style="
display:flex;flex-wrap:wrap;padding-right:4px;
justify-content:center;align-content:center;color:#25d366;"></span>
</div>
</div>
</div>

</div>

</div>
</template>
<script>
import { mapState } from 'vuex';
import VueHorizontal from 'vue-horizontal';
export default {
name: 'TopBarPC',
components: {VueHorizontal},
//props: {msg: String},
data(){
return {
input_val:'',serach_open:false
}
},
methods:{
send_number:function(val){
var s=val.toString().replace('0', '').replace(/\s/g, '');
return s;
},
lazy_update(){
this.$store.commit("lazy_update");
},
toEnglishNumber:function () {
//var strNum=this.input_val;٢٣٤
var pn = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
var en = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
var an = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
//var cache = strNum;
for (var i = 0; i < 10; i++) {
var regex_fa = new RegExp(pn[i], 'g');
var regex_ar = new RegExp(an[i], 'g');
this.input_val=this.input_val.replace(regex_fa, en[i]);
this.input_val=this.input_val.replace(regex_ar, en[i]);
}
//this.Set_search_val();
//console.log(this.input_val);
return "";
},
set_pop_search:function(val){ 
this.$store.commit("set_pop_search",'');
},
set_pop_like:function(val){ 
this.$store.commit("set_pop_like",val);
},
SetViewCat: function (val) {
this.$store.commit("SetViewCat", val);
},
Set_search_val: function ()
{
if(this.input_val.length > 0){
this.$store.commit("Set_search_val", this.input_val);
this.$store.commit("set_pop_search",'');
this.lazy_update();
} 
}, 
SetviewNav: function (val) {
this.$store.commit("SetviewNav", val);
},
SetHideNav: function (val) {
this.$store.commit("SetHideNav", val);
},
setpage: function (val) {
this.$store.commit("setpage", val);
},
setPaddingTop: function () {
if(this.hide_navbar=='menu'){
$('.main-home-page').css('padding-top','152px');
}
else{$('.main-home-page').css('padding-top','60px');}
}
},
watch:{
// whenever question changes, this function will run
input_val: function (v) { 
if (v.length > 0) {this.$store.commit("Set_search_val", v);}
if (v.length == 0) {this.$store.commit("Set_search_val", '');}
//this.$refs.horizontal.scrollToIndex(this.pageview + 1);
},
pageview: function () {
//this.$refs.horizontal.scrollToIndex(this.pageview + 1);
}
},
mounted(){
//var borowser=navigator.userAgent.toString().toLowerCase();
//if(borowser.includes('windows')||borowser.includes('mac')){
//$('.left-bar-mar').css('padding-left','38px');
//$('.right-bar-mar').css('padding-right','18px');
//}
},
//
computed: {
...mapState({
setup : state => state.setup,
selctedCat: state => state.selctedCat,
view_Nav:state => state.view_Nav,
//db : state => state.db,
cats : state => state.cats,
likes : state => state.likes,
viewCat : state => state.viewCat,
view_Nav : state => state.view_Nav,
hide_navbar: state => state.hide_navbar,
pageview : state => state.pageview
})
}
}
</script>

<style scoped>
.vbtnframes {
transform: scale(1,1);
animation: vbtnload 1.4s linear infinite alternate;
}
.spanframes {
animation: spanload 1.4s linear infinite alternate;
}
.imgframes {
animation: imgload 1.8s linear infinite alternate;
}
.favframes {
transform: scale(1,1);
animation: favload 1.4s linear infinite alternate;
}
@keyframes favload{
0% {opacity:0.7;}
25% {opacity:0.8;}
50% {opacity:0.6;transform: scale(0.9,0.9);}
75% {opacity:0.4;transform: scale(0.95,0.95);}
100% {opacity:0.2;transform: scale(1.1,1.1);}
}
@keyframes imgload{
0% {opacity:0.7;}
50% {opacity:0.6;transform: scale(0.9,0.9);}
75% {opacity:0.4;transform: scale(0.1,1);}
100% {opacity:0.2;transform: scale(1,1);}
}
@keyframes spanload{
0% {opacity:0.6;color:#aaaaaa;}
25% {opacity:0.8;color:#aaaaaa;}
50% {opacity:0.6;color:#4e4e4e;}
75% {opacity:0.4;color:#aaaaaa;}
100% {opacity:0.8;color:#aaaaaa;}
}
@keyframes vbtnload{
0% {opacity:0.6;transform: scale(0.95,0.95);}
25% {opacity:0.8;transform: scale(1,1);}
50% {opacity:0.6;}
75% {opacity:0.8;transform: scale(0.95,0.95);}
100% {opacity:0.6;transform: scale(1,1);}
}
</style>
<style scoped>
.j7app-spacer{
position: relative;z-index: 0;justify-content:center;
display: flex;align-items: center;justify-items:center;
}
.top-bar-pc{
position: relative; z-index: 0;
display: flex;flex-wrap:wrap;
width:100%;height:auto;
align-content:center;justify-content:center;
margin-right:auto;margin-left:auto;
background:#fff;overflow:hidden;
}
.j7app-vbtn{
position: relative;background-color: transparent;
display: inline-flex;flex: 0 1 auto;flex-grow: 0;flex-shrink: 1;
flex-basis: auto;border-radius: 0;
font-size: 14px;height: inherit;
max-width: 168px;min-width: 80px;
color: rgba(0,0,0,.54);cursor: pointer;
text-transform: none;box-shadow: none;
white-space: nowrap;user-select: none;
align-items: center;justify-content: center;
text-decoration: none;outline: 0;
-webkit-appearance: button;border-radius: 50%;
transition-duration: .28s;
transition-property: box-shadow,transform,opacity;
transition-timing-function: cubic-bezier(.4,0,.2,1);
}
.j7app-vbtn-icon{
position: relative;background-color:#f9f9f9;
display: inline-flex;flex: 0 1 auto;flex-grow: 0;flex-shrink: 1;
flex-basis: auto;border-radius: 0;
font-size: 14px;height: inherit;
color: rgba(0,0,0,.54);cursor: pointer;
text-transform: none;box-shadow: none;
white-space: nowrap;user-select: none;
align-items: center;justify-content: center;
text-decoration: none;outline: 0;border:1px solid #eee;
-webkit-appearance: button;border-radius: 50%;
transition-duration: .28s;
transition-property: box-shadow,transform,opacity;
transition-timing-function: cubic-bezier(.4,0,.2,1);
}
.j7app-span-icon{
display:flex;width:100%;
justify-content:center;align-content:center;
}
.j7app-span-icon.active{}
</style>
<style scoped>
.absolute-serach-warp{
position:absolute;
width:100%;height:55px;z-index:10;
}
.absolute-serach-box{
position:relative; background:#fff;
display:flex;flex-wrap:wrap;margin-right:auto;
height:55px;width:100%;padding:0 14px 0 14px;
justify-content:center;align-content:center;
}
.absolute-serach-box-input{
display:flex;
color:rgba(16, 39, 95,.6);
border:1px solid rgba(16, 39, 95,.2);
}
@media (min-width: 200px) and (max-width: 575px){
.j7app-appbar{
height:55px;
align-content:center;
justify-content:center;
background:#fff;overflow:hidden;
}
.right-bar{
display:flex;flex-wrap:wrap;
align-content:center;max-width:192px;
width:25%;height:100%;
justify-content:center;margin-right:0px;
}
.left-bar{
display:flex;flex-wrap:wrap;height:100%;
align-content:center;
justify-content:center;
width:auto;overflow:hidden;margin-left:7px;
margin-right:auto;
}
}
@media (min-width: 600px){
.right-bar{
display:flex;flex-wrap:wrap;
align-content:center;max-width:192px;
width:100%;height:100%;
justify-content:center;margin-right:0px;
}
.left-bar{
display:flex;flex-wrap:wrap;height:100%;
align-content:center;
width:100%;overflow:hidden;margin-left:7px;
margin-right:auto;max-width:145px;
}
}
</style>
